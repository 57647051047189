import { classes as classesServices } from '@coligo-org/fe-common/services';
import { schoolAdminMessages } from '@coligo-org/fe-common/translations';

import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const deleteItemStart = () => ({
  type: Types.DELETE_ITEM_START,
});

export const deleteItemEnd = () => ({
  type: Types.DELETE_ITEM_END,
});
const ERROR_TYPES = {
  COURSES: '@error-code/class-has-courses',
  STUDENTS: '@error-code/class-has-students',
  CERTIFICATES: '@error-code/class-has-certificate-default',
  UNAUTHORIZED: '@error-code/unauthorized-action',
};

export default ({ classIds, callback, intl }) =>
  async dispatch => {
    try {
      dispatch(deleteItemStart());
      const { data } = await classesServices.deleteBulkClasses({
        data: { classIds },
      });
      if (data.errors.length) {
        data.errors.map(error => {
          switch (error.errorCode) {
            case ERROR_TYPES.COURSES:
              return dispatch(
                ErrorsActions.addError(
                  `${intl.formatMessage({
                    ...schoolAdminMessages.removeRelatedCoursesError,
                  })} [${error.meta.courses.map(course => course.title)}]`,
                ),
              );
            case ERROR_TYPES.STUDENTS:
              return dispatch(
                ErrorsActions.addError(
                  intl.formatMessage({
                    ...schoolAdminMessages.classHasStudentsError,
                  }),
                ),
              );
            case ERROR_TYPES.CERTIFICATES:
              return dispatch(
                ErrorsActions.addError(
                  intl.formatMessage({
                    ...schoolAdminMessages.classHasCertificatesError,
                  }),
                ),
              );
            case ERROR_TYPES.UNAUTHORIZED:
              return dispatch(
                ErrorsActions.addError(
                  intl.formatMessage({
                    ...schoolAdminMessages.noPermission,
                  }),
                ),
              );
            default:
              return null;
          }
        });
      }
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(deleteItemEnd());
    }
  };
