import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { signOut } from '../../state/actions/auth';

function Logout({ logout }) {
  const location = useLocation();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    logout({ callback: () => setIsLoggedOut(true) });
  }, [logout]);

  return isLoggedOut && <Navigate to={`/login${location.search}`} />;
}

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logout: signOut,
};
export default connect(null, mapDispatchToProps)(Logout);
