import Types from '../types';

const initialState = {
  isSaving: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.SAVE_LIST_START:
      return {
        ...reducerState,
        isSaving: true,
      };

    case Types.SAVE_LIST_END:
      return initialState;

    default:
      return reducerState;
  }
};
