import Types from '../types';

const initialState = {
  isAddingItem: false,
  isDeletingItem: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.ADD_ITEM_START:
      return {
        ...reducerState,
        isAddingItem: true,
      };
    case Types.DELETE_ITEM_START:
      return {
        ...reducerState,
        isDeletingItem: true,
      };

    case Types.ADD_ITEM_END:
    case Types.DELETE_ITEM_END:
      return initialState;

    default:
      return reducerState;
  }
};
