const moduleName = 'app';

const SET_CONSTANTS = `${moduleName}/SET_CONSTANTS`;

const UPDATE_USER_START = `${moduleName}/UPDATE_USER_START`;
const UPDATE_USER_END = `${moduleName}/UPDATE_USER_END`;

const SWITCH_USER_START = `${moduleName}/SWITCH_USER_START`;
const SWITCH_USER_END = `${moduleName}/SWITCH_USER_END`;

const SET_FEATURE_FLAGS = `${moduleName}/SET_FEATURE_FLAGS`;

export default {
  SET_CONSTANTS,
  UPDATE_USER_START,
  UPDATE_USER_END,
  SWITCH_USER_START,
  SWITCH_USER_END,
  SET_FEATURE_FLAGS,
};
