import { assessments as assessmentsServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const editAssessmentStart = () => ({
  type: coursesTypes.CREATE_ASSESSMENT_START,
});

export const editAssessmentEnd = () => ({
  type: coursesTypes.CREATE_ASSESSMENT_END,
});

export default ({
    assessmentId,
    data,
    onUploadProgress,
    removedAssessmentAttachments,
    callback,
  }) =>
  async dispatch => {
    try {
      dispatch(editAssessmentStart());
      await assessmentsServices.updateAssessment({
        assessmentId,
        data,
        onUploadProgress,
      });
      if (removedAssessmentAttachments.urls.length > 0) {
        await assessmentsServices.removeAssessmentAttachments({
          assessmentId,
          attachments: removedAssessmentAttachments,
        });
      }
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(editAssessmentEnd());
    }
  };
