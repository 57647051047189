import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import useRoles from 'hooks/useRoles';
import styles from './styles';

function baseRole(role, allRoles) {
  switch (role) {
    case allRoles.SUPER_ADMIN:
      return 'Super Admin';
    case allRoles.ORGANIZATION_ADMIN:
      return 'Organization Admin';
    case allRoles.SCHOOL_ADMIN:
      return 'School Admin';
    case allRoles.HOD:
      return 'HOD';
    case allRoles.PRINCIPAL:
      return 'Principal';
    case allRoles.PARENT:
      return 'Parent';
    case allRoles.TEACHER:
      return 'Teacher';
    case allRoles.STUDENT:
      return 'Student';
    default:
      return 'N/A';
  }
}

const useStyles = makeStyles(styles);

function ChooseAccount({ accounts, goToPending, backToRequest }) {
  const [chosenUser, setChosenUser] = useState(' ');
  const { allRoles } = useRoles();

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" className={classes.title}>
        Select an account to reset it&apos;s password
      </Typography>
      <Select
        variant="standard"
        input={<OutlinedInput />}
        value={chosenUser}
        onChange={event => setChosenUser(event.target.value)}
      >
        <MenuItem value=" ">Select an Account</MenuItem>
        {accounts?.map(item => (
          <MenuItem key={item.username} value={item}>
            <img
              src={item.profile.avatar}
              className={classes.usersImage}
              alt="avatar"
            />
            &nbsp;
            {item.profile.firstName} - {baseRole(item.baseRole.name, allRoles)}
          </MenuItem>
        ))}
      </Select>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={chosenUser === ' '}
        onClick={() => {
          goToPending(chosenUser);
        }}
      >
        Continue
      </Button>
      <Typography>
        <Typography variant="caption" className={classes.caption}>
          Not your account?{' '}
        </Typography>
        <Typography
          variant="caption"
          className={classes.hyperLink}
          onClick={() => {
            backToRequest();
          }}
        >
          enter another email
        </Typography>
      </Typography>
    </Paper>
  );
}

ChooseAccount.propTypes = {
  accounts: PropTypes.shape().isRequired,
  backToRequest: PropTypes.func.isRequired,
  goToPending: PropTypes.func.isRequired,
};

export default ChooseAccount;
