import { materials as MaterialsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';
import getCourseMaterials from './getCourseMaterials';

export const deleteMaterialStart = () => ({
  type: coursesTypes.DELETE_MATERIAL_START,
});

export const deleteMaterialEnd = () => ({
  type: coursesTypes.DELETE_MATERIAL_END,
});

export default ({ courseId, materialId, callback }) =>
  async dispatch => {
    try {
      dispatch(deleteMaterialStart());
      await MaterialsService.deleteMaterial({ materialId });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(deleteMaterialEnd());
      dispatch(getCourseMaterials(courseId));
    }
  };
