const moduleName = 'academicYears';

const GET_ITEM_START = `${moduleName}/GET_ITEM_START`;
const GET_ITEM_END = `${moduleName}/GET_ITEM_END`;
const SET_ITEM = `${moduleName}/SET_ITEM`;
const CLEAR_ITEM = `${moduleName}/CLEAR_ITEM`;

const SELECT_ITEM = `${moduleName}/SELECT_ITEM`;

const CREATE_ITEM_START = `${moduleName}/CREATE_ITEM_START`;
const CREATE_ITEM_END = `${moduleName}/CREATE_ITEM_END`;

const DELETE_ITEM_START = `${moduleName}/DELETE_ITEM_START`;
const DELETE_ITEM_END = `${moduleName}/DELETE_ITEM_END`;

export default {
  GET_ITEM_START,
  SET_ITEM,
  CLEAR_ITEM,
  SELECT_ITEM,
  GET_ITEM_END,
  CREATE_ITEM_START,
  CREATE_ITEM_END,
  DELETE_ITEM_START,
  DELETE_ITEM_END,
};
