import Types from '../types';

const INITIAL_STATE = {
  titleProps: { mainTitle: null, subTitle: null, backUrl: null },
};

export default function titlePropsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CHANGE_HEADER_TITLE_PROPS:
      return { ...state, titleProps: action.payload };
    case Types.RESET_HEADER_TITLE_PROPS:
      return { ...state, titleProps: INITIAL_STATE };
    default:
      return state;
  }
}
