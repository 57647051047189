import { materials as materialsServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getFolderContentStart = () => ({
  type: coursesTypes.GET_FOLDER_CONTENT_START,
});

export const getFolderContentEnd = () => ({
  type: coursesTypes.GET_FOLDER_CONTENT_END,
});

export default ({ folderId, params, callback }) =>
  async dispatch => {
    try {
      dispatch(getFolderContentStart());
      const { data } = await materialsServices.getFolderContent({
        folderId,
        params,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getFolderContentEnd());
    }
  };
