import { combineReducers } from 'redux';

import state from './reducers/state';

const gradebook = combineReducers({
  state,
});

export default combineReducers({
  gradebook,
});
