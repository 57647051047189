const moduleName = 'stages';

const SAVE_LIST_START = `${moduleName}/SAVE_LIST_START`;
const SAVE_LIST_END = `${moduleName}/SAVE_LIST_END`;
const SAVE_LIST = `${moduleName}/SAVE_LIST`;

export default {
  SAVE_LIST,
  SAVE_LIST_START,
  SAVE_LIST_END,
};
