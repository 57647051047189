import loginBackground from 'common/assets/img/login-background.jpg';
import { whiteColor, certificateHiddenText } from 'common/assets/jss/appStyle';

export default theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${loginBackground})`,
    backgroundPosition: 'center',
    [theme.breakpoints.up(400)]: {
      backgroundSize: 'cover',
    },
  },
  container: {
    padding: theme.spacing(3),
    backgroundColor: whiteColor,
    width: '70vw',
    borderRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    '&  > figure': {
      width: '370px',
      maxHeigh: '370px',
      '& > img': {
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
      },
    },
  },
  iconError: {
    fontSize: ' 26em',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  subtitle: {
    color: certificateHiddenText,
    fontWeight: '400',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  btn: {
    background:
      'linear-gradient(145deg, #6AB8B8 0%, #2677A3 0.01%, #71C1BE 100%)',
    width: '200px',
    padding: theme.spacing(1, 2),
  },
});
