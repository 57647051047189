import { users as userServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const setHiddenGrades = hiddenGrades => ({
  type: coursesTypes.SET_HIDDEN_GRADES,
  payload: hiddenGrades,
});

export default ({ userId, callback }) =>
  async dispatch => {
    try {
      const { data } = await userServices.getUserGradebookVisibility({
        userId,
      });
      dispatch(setHiddenGrades(data));
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    }
  };
