import { materials as MaterialsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const copyMaterialStart = () => ({
  type: coursesTypes.CREATE_MATERIAL_START,
});

export const copyMaterialEnd = () => ({
  type: coursesTypes.CREATE_MATERIAL_END,
});

export default ({ materialId, data, callback }) =>
  async dispatch => {
    try {
      dispatch(copyMaterialStart());
      await MaterialsService.copyMaterial({ materialId, data });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(copyMaterialEnd());
    }
  };
