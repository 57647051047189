import Types from '../types';

const initialState = {
  isLoadingList: false,
  isLoadingItem: false,
  isCreatingItem: false,
  isImportingItems: false,
  isUpdatingItem: false,
  isDeletingItem: false,
  isEditingItem: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_LIST_START:
      return {
        ...reducerState,
        isLoadingList: true,
      };

    case Types.FETCH_ITEM_START:
      return {
        ...reducerState,
        isLoadingItem: true,
      };

    case Types.CREATE_ITEM_START:
      return {
        ...reducerState,
        isCreatingItem: true,
      };

    case Types.IMPORT_ITEMS_START:
      return {
        ...reducerState,
        isImportingItems: true,
      };

    case Types.UPDATE_ITEM_START:
      return {
        ...reducerState,
        isUpdatingItem: true,
      };

    case Types.DELETE_ITEM_START:
      return {
        ...reducerState,
        isDeletingItem: true,
      };

    case Types.CERTIFICATE_OPTIONS_EDIT_START:
      return {
        ...reducerState,
        isEditingItem: true,
      };

    case Types.FETCH_LIST_END:
      return {
        ...reducerState,
        isLoadingList: false,
      };

    case Types.FETCH_ITEM_END:
      return {
        ...reducerState,
        isLoadingItem: false,
      };

    case Types.CREATE_ITEM_END:
      return {
        ...reducerState,
        isCreatingItem: false,
      };

    case Types.IMPORT_ITEMS_END:
      return {
        ...reducerState,
        isImportingItems: false,
      };

    case Types.UPDATE_ITEM_END:
      return {
        ...reducerState,
        isUpdatingItem: false,
      };

    case Types.DELETE_ITEM_END:
      return {
        ...reducerState,
        isDeletingItem: false,
      };

    case Types.CERTIFICATE_OPTIONS_EDIT_END:
      return {
        ...reducerState,
        isEditingItem: false,
      };

    default:
      return reducerState;
  }
};
