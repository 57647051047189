import { combineReducers } from 'redux';

import selectedChild from './reducers/selectedChild';

const children = combineReducers({
  selectedChild,
});

export default combineReducers({
  children,
});
