// reducers
import header from './reducers/titleProps';

// actions
import changeHeaderTitleProps from './actions/changeHeaderTitleProps';
import resetHeaderTitleProps from './actions/resetHeaderTitleProps';

export const HeaderActions = {
  changeHeaderTitleProps,
  resetHeaderTitleProps,
};

export default header;
