import { materials as MaterialsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getNoteStart = () => ({
  type: coursesTypes.GET_MATERIAL_START,
});

export const getNoteEnd = () => ({
  type: coursesTypes.GET_MATERIAL_END,
});

export default ({ noteId, callback }) =>
  async dispatch => {
    try {
      dispatch(getNoteStart());
      const { data } = await MaterialsService.getNote({ noteId });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getNoteEnd());
    }
  };
