import { submissions as SubmissionsServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const submitSubmissionStart = () => ({
  type: coursesTypes.SUBMIT_SUBMISSION_START,
});

export const submitSubmissionEnd = () => ({
  type: coursesTypes.SUBMIT_SUBMISSION_END,
});

export default ({ submissionId, callback }) =>
  async dispatch => {
    try {
      dispatch(submitSubmissionStart());
      await SubmissionsServices.submitSubmission({ submissionId });
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      if (callback) callback();
      dispatch(submitSubmissionEnd());
    }
  };
