import { gradebookSystems as gradebookSystemsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../../../../common/error/state';
import fetchList from './fetchList';
import logger from '../../../../../../../../../../common/services/logService';
import Types from '../types';

export const updateItemStart = () => ({
  type: Types.UPDATE_ITEM_START,
});

export const updateItemEnd = () => ({
  type: Types.UPDATE_ITEM_END,
});

export default (schoolId, itemId, itemProps, callback) => async dispatch => {
  try {
    dispatch(updateItemStart());
    await gradebookSystemsService.updateGradebookSystem(itemId, itemProps);
    if (callback) callback();
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(fetchList(schoolId));
    dispatch(updateItemEnd());
  }
};
