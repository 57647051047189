import Types from '../types';

const INITIAL_STATE = [];

export default (reducerState = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_LINKED_ACCOUNTS:
      return action.payload;
    default:
      return reducerState;
  }
};
