import { semesters as semesterServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import { SchoolActions } from '../../../school/state';
import logger from '../../../../../../../common/services/logService';

export default (schoolId, semesterId, callback) => async dispatch => {
  try {
    await semesterServices.archiveSemester(semesterId);
    callback();
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(SchoolActions.fetchItem(schoolId));
  }
};
