export default allRoles => {
  return {
    [allRoles.SUPER_ADMIN]: '/super-admin',
    [allRoles.ORGANIZATION_ADMIN]: '/org-admin',
    [allRoles.SCHOOL_ADMIN]: '/school-admin',
    [allRoles.HOD]: '/head-of-department',
    [allRoles.PRINCIPAL]: '/principal',
    [allRoles.PARENT]: '/parent',
    [allRoles.TEACHER]: '/teacher',
    [allRoles.STUDENT]: '/student',
  };
};
