import { courses as coursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const updateItemStart = () => ({
  type: Types.UPDATE_ITEM_START,
});

export const updateItemEnd = () => ({
  type: Types.UPDATE_ITEM_END,
});

export default ({ courseId, studentId, callback }) =>
  async dispatch => {
    try {
      dispatch(updateItemStart());
      await coursesServices.addStudent({ courseId, studentId });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateItemEnd());
    }
  };
