import Types from '../types';

const initialState = {
  isGettingCourseTemplates: false,
  isCreatingQuestion: false,
  isFetchingQuestions: false,
  isFetchingQuestion: false,
  isDeletingQuestion: false,
  isChangingQuestionState: false,
  isUpdatingQuestion: false,
  isGettingSchoolTags: false,
  isCreatingTag: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.GET_COURSE_TEMPLATES_START:
      return {
        ...reducerState,
        isGettingCourseTemplates: true,
      };
    case Types.CREATE_QUESTION_START:
      return {
        ...reducerState,
        isCreatingQuestion: true,
      };
    case Types.GET_QUESTIONS_START:
      return {
        ...reducerState,
        isFetchingQuestions: true,
      };
    case Types.GET_QUESTION_START:
      return {
        ...reducerState,
        isFetchingQuestion: true,
      };
    case Types.DELETE_QUESTION_START:
      return {
        ...reducerState,
        isDeletingQuestion: true,
      };
    case Types.CHANGE_QUESTION_STATE_START:
      return {
        ...reducerState,
        isChangingQuestionState: true,
      };
    case Types.UPDATE_QUESTION_START:
      return {
        ...reducerState,
        isUpdatingQuestion: true,
      };
    case Types.UPDATE_QUESTION_END:
      return {
        ...reducerState,
        isUpdatingQuestion: false,
      };
    case Types.GET_QUESTION_END:
      return {
        ...reducerState,
        isFetchingQuestion: false,
      };
    case Types.GET_COURSE_TEMPLATES_END:
      return {
        ...reducerState,
        isGettingCourseTemplates: false,
      };
    case Types.CREATE_QUESTION_END:
      return {
        ...reducerState,
        isCreatingQuestion: false,
      };
    case Types.GET_QUESTIONS_END:
      return {
        ...reducerState,
        isFetchingQuestions: false,
      };

    case Types.DELETE_QUESTION_END:
      return {
        ...reducerState,
        isDeletingQuestion: false,
      };
    case Types.CHANGE_QUESTION_STATE_END:
      return {
        ...reducerState,
        isChangingQuestionState: false,
      };
    case Types.GET_SCHOOL_TAGS_START:
      return {
        ...reducerState,
        isGettingSchoolTags: true,
      };
    case Types.GET_SCHOOL_TAGS_END:
      return {
        ...reducerState,
        isGettingSchoolTags: false,
      };
    case Types.CREATE_SCHOOL_TAG_START:
      return {
        ...reducerState,
        isCreatingTag: true,
      };
    case Types.CREATE_SCHOOL_TAG_END:
      return {
        ...reducerState,
        isCreatingTag: false,
      };
    default:
      return reducerState;
  }
};
