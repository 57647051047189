import {
  assessments as AssessmentsServices,
  homeworks as HomeworksServices,
} from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const restoreAssessmentStart = () => ({
  type: coursesTypes.RESTORE_ASSESSMENT_START,
});

export const restoreAssessmentEnd = () => ({
  type: coursesTypes.RESTORE_ASSESSMENT_END,
});

const restoreAssessment = (isHomework, assessmentId) =>
  isHomework
    ? HomeworksServices.restoreHomework({ homeworkId: assessmentId })
    : AssessmentsServices.restoreAssessment({ assessmentId });

export default (
    { assessmentId, callback, isHomework } = { isHomework: false },
  ) =>
  async dispatch => {
    try {
      dispatch(restoreAssessmentStart());
      await restoreAssessment(isHomework, assessmentId);
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(restoreAssessmentEnd());
    }
  };
