import { courses as CoursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';
import getCourseMaterials from './getCourseMaterials';

export const createLinkStart = () => ({
  type: coursesTypes.CREATE_MATERIAL_START,
});

export const createLinkEnd = () => ({
  type: coursesTypes.CREATE_MATERIAL_END,
});

export default ({ courseId, link, callback }) =>
  async dispatch => {
    try {
      dispatch(createLinkStart());
      await CoursesServices.createLink({ courseId, link });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        const errorMessage =
          error.response.status === 400
            ? 'Make sure all fields are filled and the link is valid'
            : error.response.data.message;
        dispatch(ErrorsActions.addError(errorMessage));
      }
    } finally {
      dispatch(createLinkEnd());
      dispatch(getCourseMaterials(courseId));
    }
  };
