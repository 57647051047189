import { courses as coursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const createAssessmentStart = () => ({
  type: coursesTypes.CREATE_ASSESSMENT_START,
});

export const createAssessmentEnd = () => ({
  type: coursesTypes.CREATE_ASSESSMENT_END,
});

export default ({ courseId, data, onUploadProgress, callback }) =>
  async dispatch => {
    try {
      dispatch(createAssessmentStart());
      const result = await coursesServices.createAssessment({
        courseId,
        data,
        onUploadProgress,
      });
      if (callback) callback(result.data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(createAssessmentEnd());
    }
  };
