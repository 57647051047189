import getDefaultRolesRoutes from 'common/helpers/getDefaultRolesRoutes';

export default (user, allRoles) => {
  const DEFAULT_ROLES_ROUTES = getDefaultRolesRoutes(allRoles);
  if (user && user.baseRole) {
    switch (user.baseRole.name) {
      case allRoles.SCHOOL_ADMIN:
        return `${DEFAULT_ROLES_ROUTES[user.baseRole.name]}/${user.schoolId}`;
      case allRoles.ORGANIZATION_ADMIN:
        return `${DEFAULT_ROLES_ROUTES[user.baseRole.name]}/${
          user.organizationAdminInfo.organizationId
        }`;
      default:
        return DEFAULT_ROLES_ROUTES[user.baseRole.name];
    }
  } else {
    return '/login';
  }
};
