import { users as usersServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import { usersTypes } from '../../reducers/types';
import logger from '../../../common/services/logService';

export const getUserStart = () => ({
  type: usersTypes.GET_USER_START,
});

export const getUserEnd = () => ({
  type: usersTypes.GET_USER_END,
});

export const setUser = user => ({
  type: usersTypes.SET_USER,
  payload: user,
});

export default ({ callback }) =>
  async dispatch => {
    try {
      dispatch(getUserStart());
      const { data } = await usersServices.getUserData();
      dispatch(setUser(data));
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getUserEnd());
    }
  };
