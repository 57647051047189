import Types from '../types';

const initialState = {
  isLoadingList: false,
  list: [],
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_GRADING_SYSTEMS_START:
      return {
        ...reducerState,
        isLoadingList: true,
      };

    case Types.FETCH_GRADING_SYSTEMS_END:
      return {
        ...reducerState,
        isLoadingList: false,
      };

    case Types.SET_GRADING_SYSTEMS:
      return {
        ...reducerState,
        list: action.payload,
      };

    default:
      return reducerState;
  }
};
