import { academicYear as academicYearServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const getItemStart = () => ({
  type: Types.GET_ITEM_START,
});

export const getItemEnd = () => ({
  type: Types.GET_ITEM_END,
});

export const setItem = item => ({
  type: Types.SELECT_ITEM,
  payload: item,
});

export default itemId => async dispatch => {
  try {
    dispatch(getItemStart());
    const response = await academicYearServices.getAcademicYear(itemId);
    dispatch(setItem(response.data));
    if (itemId) {
      localStorage.setItem('selectedAcademicYearId', itemId);
    }
  } catch (error) {
    logger.log(error);
    dispatch(ErrorsActions.addError(error.response.data.message));
  } finally {
    dispatch(getItemEnd());
  }
};
