import { users as userServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const fetchMessagesStart = () => ({
  type: Types.FETCH_MESSAGES_START,
});

export const fetchMessagesEnd = () => ({
  type: Types.FETCH_MESSAGES_END,
});

export const setMessages = (messages, shouldResetMessages) => ({
  type: Types.SET_MESSAGES,
  payload: messages,
  shouldResetMessages,
});

export const setTotalCount = count => ({
  type: Types.SET_TOTAL_COUNT,
  payload: count,
});

export default ({ userId, params }, shouldResetMessages = true) =>
  async dispatch => {
    try {
      if (shouldResetMessages) dispatch(fetchMessagesStart());
      const { data } = await userServices.getUserMessages({ userId, params });

      dispatch(setMessages(data.data, shouldResetMessages));
      dispatch(setTotalCount(data.total));
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(fetchMessagesEnd());
    }
  };
