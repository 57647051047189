import Types from '../types';

const initialState = {
  isLoadingItem: false,
  isUpdatingItem: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_ITEM_START:
      return {
        ...reducerState,
        isLoadingItem: true,
      };

    case Types.UPDATE_ITEM_START:
      return {
        ...reducerState,
        isSavingItem: true,
      };

    case Types.FETCH_ITEM_END:
    case Types.UPDATE_ITEM_END:
      return initialState;

    default:
      return reducerState;
  }
};
