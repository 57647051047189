const moduleName = 'users';

const UPDATE_USER_START = `${moduleName}/UPDATE_USER_START`;
const UPDATE_USER_END = `${moduleName}/UPDATE_USER_END`;

const GET_USER_START = `${moduleName}/GET_USER_START`;
const GET_USER_END = `${moduleName}/GET_USER_END`;
const SET_USER = `${moduleName}/SET_USER`;

const GET_USER_COURSES_LIST_START = `${moduleName}/GET_USER_COURSES_LIST_START`;
const GET_USER_COURSES_LIST_END = `${moduleName}/GET_USER_COURSES_LIST_END`;

const GET_PRINCIPAL_CLASSES_START = `${moduleName}/GET_PRINCIPAL_CLASSES_START`;
const GET_PRINCIPAL_CLASSES_END = `${moduleName}/GET_PRINCIPAL_CLASSES_END`;

export default {
  UPDATE_USER_START,
  UPDATE_USER_END,

  GET_USER_START,
  GET_USER_END,
  SET_USER,

  GET_USER_COURSES_LIST_START,
  GET_USER_COURSES_LIST_END,

  GET_PRINCIPAL_CLASSES_START,
  GET_PRINCIPAL_CLASSES_END,
};
