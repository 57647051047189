import { materials as MaterialsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';
import getCourseMaterials from './getCourseMaterials';

export const updateLinkStart = () => ({
  type: coursesTypes.UPDATE_MATERIAL_START,
});

export const updateLinkEnd = () => ({
  type: coursesTypes.UPDATE_MATERIAL_END,
});

export default ({ courseId, materialId, link, callback }) =>
  async dispatch => {
    try {
      dispatch(updateLinkStart());
      await MaterialsService.updateLink({ materialId, link });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateLinkEnd());
      dispatch(getCourseMaterials(courseId));
    }
  };
