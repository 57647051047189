export default theme => ({
  body: {
    height: '100vh',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  '@keyframes flipPages': {
    '0%': {
      '-webkit-transform': 'rotateY(0deg)',
      transform: 'rotateY(0deg)',
    },
    '50%, 80%': {
      '-webkit-transform': 'rotateY(-180deg)',
      transform: 'rotateY(-180deg)',
    },
    '90%, 100%': {
      opacity: 0,
      '-webkit-transform': 'rotateY(-180deg)',
      transform: 'rotateY(-180deg)',
    },
  },
  book: {
    height: '40px',
    position: 'relative',
    width: '40px',
    '-webkit-perspective': '60px',
    perspective: '60px',
    '& > div': {
      '-webkit-animation': '$flipPages 2s infinite',
      animation: '$flipPages 2s infinite',
      background: `${theme.palette.primary.main}`,
      height: '100%',
      position: 'absolute',
      left: '50%',
      '-webkit-transform-origin': 'left',
      transformOrigin: 'left',
      width: '100%',
    },
    '& > :nth-child(1)': {
      '-webkit-animation-delay': '0.15s',
      'animation-delay': '0.15s',
    },
    '& > :nth-child(2)': {
      '-webkit-animation-delay': '0.3s',
      'animation-delay': '0.3s',
    },
    '& > :nth-child(3)': {
      '-webkit-animation-delay': '0.45s',
      'animation-delay': '0.45s',
    },
    '& > :nth-child(4)': {
      '-webkit-animation-delay': '0.6s',
      'animation-delay': '0.6s',
    },
    '& > :nth-child(5)': {
      '-webkit-animation-delay': '0.75s',
      'animation-delay': '0.75s',
    },
  },
});
