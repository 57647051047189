import { combineReducers } from 'redux';

import academicYears from '../modules/academicYears/state';
import school from '../modules/school/state';
import semesters from '../modules/semesters/state';
import stages from '../modules/stages/state';
import subjects from '../modules/subjects/state';
import classes from '../modules/classes/state';
import courseTemplates from '../modules/courseTemplates/state';
import courses from '../modules/courses/state';
import gradeCategories from '../modules/gradebook/views/standardGradeBook/gradeCategories/state';
import stageGradebookDisplays from '../modules/gradebook/views/standardGradeBook/stageGradebookDisplays/state';
import gradebookSystems from '../modules/gradebook/views/standardGradeBook/gradebookSystems/state';
import courseGradebookWeights from '../modules/gradebook/views/standardGradeBook/courseGradebookWeights/state';
import gradebook from '../modules/gradebook/views/list/state';

export default combineReducers({
  school,
  stages,
  subjects,
  academicYears,
  semesters,
  classes,
  courseTemplates,
  courses,
  gradeCategories,
  stageGradebookDisplays,
  gradebookSystems,
  courseGradebookWeights,
  gradebook,
});
