import { assessments as assessmentsServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getAssessmentSubmissionsStart = () => ({
  type: coursesTypes.GET_ASSESSMENT_SUBMISSIONS_START,
});

export const getAssessmentSubmissionsEnd = () => ({
  type: coursesTypes.GET_ASSESSMENT_SUBMISSIONS_END,
});

export default ({ assessmentId, callback }) =>
  async dispatch => {
    try {
      dispatch(getAssessmentSubmissionsStart());
      const { data } = await assessmentsServices.getAssessmentSubmissions({
        assessmentId,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getAssessmentSubmissionsEnd());
    }
  };
