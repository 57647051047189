import { users as usersServices } from '@coligo-org/fe-common/services';
import logger from '../../../common/services/logService';
import { ErrorsActions } from '../error';
import { usersTypes } from '../../reducers/types';

export const getPrincipalClassesStart = () => ({
  type: usersTypes.GET_PRINCIPAL_CLASSES_START,
});

export const getPrincipalClassesEnd = () => ({
  type: usersTypes.GET_PRINCIPAL_CLASSES_END,
});

export default ({ principalId, academicYearId, callback }) =>
  async dispatch => {
    try {
      dispatch(getPrincipalClassesStart());
      const { data } = await usersServices.getPrincipalClasses({
        principalId,
        academicYearId,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getPrincipalClassesEnd());
    }
  };
