const moduleName = 'messages';

const FETCH_MESSAGES_START = `${moduleName}/FETCH_MESSAGES_START`;
const FETCH_MESSAGES_END = `${moduleName}/FETCH_MESSAGES_END`;
const SET_MESSAGES = `${moduleName}/SET_MESSAGES`;
const SET_UNREAD_COUNT = `${moduleName}/SET_UNREAD_COUNT`;
const SET_TOTAL_COUNT = `${moduleName}/SET_TOTAL_COUNT`;

const FETCH_COURSES_START = `${moduleName}/FETCH_COURSES_START`;
const FETCH_COURSES_END = `${moduleName}/FETCH_COURSES_END`;
const SET_COURSES = `${moduleName}/SET_COURSES`;

const FETCH_USERS_START = `${moduleName}/FETCH_USERS_START`;
const FETCH_USERS_END = `${moduleName}/FETCH_USERS_END`;
const SET_USERS = `${moduleName}/SET_USERS`;

const UPDATE_MESSAGE_START = `${moduleName}/UPDATE_MESSAGE_START`;
const UPDATE_MESSAGE_END = `${moduleName}/UPDATE_MESSAGE_END`;

const DELETE_MESSAGE_START = `${moduleName}/DELETE_MESSAGE_START`;
const DELETE_MESSAGE_END = `${moduleName}/DELETE_MESSAGE_END`;

const ACTIVATE_VIEW_AS_MODE = `${moduleName}/ACTIVATE_VIEW_AS_MODE`;
const CANCEL_VIEW_AS_MODE = `${moduleName}/CANCEL_VIEW_AS_MODE`;

export default {
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_END,
  SET_MESSAGES,
  SET_UNREAD_COUNT,
  SET_TOTAL_COUNT,

  FETCH_COURSES_START,
  FETCH_COURSES_END,
  SET_COURSES,

  FETCH_USERS_START,
  FETCH_USERS_END,
  SET_USERS,

  UPDATE_MESSAGE_START,
  UPDATE_MESSAGE_END,

  DELETE_MESSAGE_START,
  DELETE_MESSAGE_END,

  ACTIVATE_VIEW_AS_MODE,
  CANCEL_VIEW_AS_MODE,
};
