import { materials as MaterialsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const updateNoteStart = () => ({
  type: coursesTypes.UPDATE_MATERIAL_START,
});

export const updateNoteEnd = () => ({
  type: coursesTypes.UPDATE_MATERIAL_END,
});

export default ({ noteId, data, deletedAttachments, callback }) =>
  async dispatch => {
    try {
      dispatch(updateNoteStart());
      await MaterialsService.updateNote({ noteId, data });
      const deletedUrls = deletedAttachments.map(item => item.url);
      if (deletedUrls.length > 0) {
        await MaterialsService.deleteAttachment({
          noteId,
          data: { urls: deletedUrls },
        });
      }
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateNoteEnd());
    }
  };
