const moduleName = 'subjects';

const ADD_ITEM_START = `${moduleName}/ADD_ITEM_START`;
const ADD_ITEM_END = `${moduleName}/SAVE_LIST_END`;

const DELETE_ITEM_START = `${moduleName}/DELETE_ITEM_START`;
const DELETE_ITEM_END = `${moduleName}/DELETE_ITEM_END`;

export default {
  ADD_ITEM_START,
  ADD_ITEM_END,
  DELETE_ITEM_START,
  DELETE_ITEM_END,
};
