/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

const defaultDataFormatter = res => res?.data ?? res;

// Notes: we will follow key convention as bellow
// Key = [nameOfFunction , params-object-sent-to-service]
// ex. key = [userServices.getStudent, {studentId: 'bla-bla'} ]
// if params-object is sent from two components with same value of keys but different order:
// React Query will hash it and the final key will be the same

/**
 *
 * @summary hook that helps you manage async state and cache it
 * @param {string} key - key that identify the request. when it is changed, data will be refetch
 * @param {function} fetcher - async function or api call
 * @param {object} [options] - options sent to react-query
 * @param {function} [dataFormatter] - format the response data
 * @param {boolean} [isInfinite] - determine if useQuery service or useInfiniteQuery
 */

const useCachedService = ({
  key,
  fetcher,
  dataFormatter = defaultDataFormatter,
  isInfinite = false,
  onError,
  onSuccess,
  ...options
}) => {
  const formattedKey = Array.isArray(key) ? key : [key];

  const service = isInfinite ? useInfiniteQuery : useQuery;
  const {
    data,
    error,
    isLoading,
    isError,
    isSuccess,
    dataUpdatedAt,
    errorUpdatedAt,
    failureCount,
    isFetched,
    isFetchedAfterMount,
    isFetching,
    isPaused,
    isLoadingError,
    isPlaceholderData,
    isPreviousData,
    isRefetchError,
    isRefetching,
    isStale,
    refetch,
    remove,
    status,
    fetchStatus,
  } = service({
    queryKey: formattedKey,
    queryFn: fetcher,
    ...options,
  });

  useEffect(() => {
    if (isError && onError) {
      onError(error);
    }
  }, [error, isError]);

  useEffect(() => {
    if (isSuccess && onSuccess) {
      onSuccess(data);
    }
  }, [data, isSuccess]);

  // Usually data = {data , status , statusText, headers ,.... etc }
  // That's why the default value for dataFormatter extract the real data
  return {
    data: dataFormatter(data),
    error,
    isError,
    isLoading,
    isSuccess,
    dataUpdatedAt,
    errorUpdatedAt,
    failureCount,
    isFetched,
    isFetchedAfterMount,
    isFetching,
    isPaused,
    isLoadingError,
    isPlaceholderData,
    isPreviousData,
    isRefetchError,
    isRefetching,
    isStale,
    refetch,
    remove,
    status,
    fetchStatus,
  };
};

export default useCachedService;
