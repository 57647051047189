import { combineReducers } from 'redux';
import getItem from './actions/getItem';
import clearItem from './actions/clearItem';
import selectItem from './actions/selectItem';

import createItem from './actions/createItem';
import deleteItem from './actions/deleteItem';

import state from './reducers/state';
import data from './reducers/data';

export const AcademicYearActions = {
  getItem,
  clearItem,
  selectItem,
  createItem,
  deleteItem,
};

export default combineReducers({
  state,
  data,
});
