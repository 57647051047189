import Types from '../types';

const initialState = {
  isFetchingSemesters: false,
  isCopyingSemesterSettings: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_SCHOOL_SEMESTERS_START:
      return {
        ...reducerState,
        isFetchingSemesters: true,
      };
    case Types.FETCH_SCHOOL_SEMESTERS_END:
      return {
        ...reducerState,
        isFetchingSemesters: false,
      };
    case Types.COPY_SEMESTER_SETTINGS_START:
      return {
        ...reducerState,
        isCopyingSemesterSettings: true,
      };
    case Types.COPY_SEMESTER_SETTINGS_END:
      return {
        ...reducerState,
        isCopyingSemesterSettings: false,
      };
    default:
      return reducerState;
  }
};
