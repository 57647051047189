import { combineReducers } from 'redux';

import getSchoolSemesters from './actions/getSchoolSemesters';
import copySemesterSettings from './actions/copySemesterSettings';

import state from './reducers/state';

export const GradebookActions = {
  getSchoolSemesters,
  copySemesterSettings,
};

export default combineReducers({
  state,
});
