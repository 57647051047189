import { academicYear as academicYearServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import { SchoolActions } from '../../../school/state';
import { setItem } from './selectItem';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

const deleteItemStart = () => ({
  type: Types.DELETE_ITEM_START,
});

const deleteItemEnd = () => ({
  type: Types.DELETE_ITEM_END,
});

export default ({ schoolId, itemId, callback }) =>
  async (dispatch, getState) => {
    dispatch(deleteItemStart());
    try {
      await academicYearServices.deleteAcademicYear(itemId);
      if (callback) callback();
      if (
        getState().schoolAdmin.academicYears.data.selectedItem._id === itemId
      ) {
        dispatch(setItem());
        localStorage.removeItem('selectedAcademicYearId');
      }
    } catch (error) {
      logger.log(error);
      dispatch(ErrorsActions.addError(error.response.data.message));
    } finally {
      dispatch(SchoolActions.fetchItem(schoolId));
      dispatch(deleteItemEnd());
    }
  };
