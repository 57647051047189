import Types from '../types';

const initialState = {
  isListingLinkedAccounts: false,
  isAddingLinkedAccount: false,
  isRemovingLinkedAccount: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.LIST_LINKED_ACCOUNTS_START:
      return { ...state, isListingLinkedAccounts: true };
    case Types.LIST_LINKED_ACCOUNTS_END:
      return { ...state, isListingLinkedAccounts: false };
    case Types.ADD_LINKED_ACCOUNT_START:
      return { ...state, isAddingLinkedAccount: true };
    case Types.ADD_LINKED_ACCOUNT_END:
      return { ...state, isAddingLinkedAccount: false };
    case Types.REMOVE_LINKED_ACCOUNT_START:
      return { ...state, isRemovingLinkedAccount: true };
    case Types.REMOVE_LINKED_ACCOUNT_END:
      return { ...state, isRemovingLinkedAccount: false };
    default:
      return state;
  }
};
