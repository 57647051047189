import { combineReducers } from 'redux';

import count from './reducers/count';
import item from './reducers/item';
import list from './reducers/list';
import queryString from './reducers/queryString';
import state from './reducers/state';

const organizations = combineReducers({
  count,
  item,
  list,
  state,
  queryString,
});

export default combineReducers({
  organizations,
});
