import { combineReducers } from 'redux';

import list from './reducers/list';
import state from './reducers/state';

import createItem from './actions/createItem';
import importClasses from './actions/importClasses';
import exportClasses from './actions/exportClasses';
import deleteItem from './actions/deleteItem';
import fetchList from './actions/fetchList';
import updateItem from './actions/updateItem';
import addStudent from './actions/addStudent';
import getClassCertificates from './actions/getClassCertificates';

export const ClassesActions = {
  fetchList,
  createItem,
  deleteItem,
  updateItem,
  addStudent,
  importClasses,
  exportClasses,
  getClassCertificates,
};

export default combineReducers({
  list,
  state,
});
