import { schools as SchoolsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../../../common/error/state';

import logger from '../../../../../../../../../common/services/logService';
import Types from '../types';

export const fetchSchoolSemestersStart = () => ({
  type: Types.FETCH_SCHOOL_SEMESTERS_START,
});

export const fetchSchoolSemestersEnd = () => ({
  type: Types.FETCH_SCHOOL_SEMESTERS_END,
});

export default ({ schoolId, requestParams, callback }) =>
  async dispatch => {
    try {
      dispatch(fetchSchoolSemestersStart());
      const { data } = await SchoolsService.getSchoolSemesters({
        schoolId,
        params: requestParams,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(fetchSchoolSemestersEnd());
    }
  };
