const moduleName = 'course-gradebook-weights';

const FETCH_LIST_START = `${moduleName}/FETCH_LIST_START`;
const FETCH_LIST_END = `${moduleName}/FETCH_LIST_END`;
const SET_LIST = `${moduleName}/SET_LIST`;

const CREATE_ITEM_START = `${moduleName}/CREATE_ITEM_START`;
const CREATE_ITEM_END = `${moduleName}/CREATE_ITEM_END`;

const UPDATE_ITEM_START = `${moduleName}/UPDATE_ITEM_START`;
const UPDATE_ITEM_END = `${moduleName}/UPDATE_ITEM_END`;

const DELETE_ITEM_START = `${moduleName}/DELETE_ITEM_START`;
const DELETE_ITEM_END = `${moduleName}/DELETE_ITEM_END`;

export default {
  FETCH_LIST_START,
  FETCH_LIST_END,

  SET_LIST,
  CREATE_ITEM_START,
  CREATE_ITEM_END,

  UPDATE_ITEM_START,
  UPDATE_ITEM_END,

  DELETE_ITEM_START,
  DELETE_ITEM_END,
};
