import { schools as schoolsServices } from '@coligo-org/fe-common/services';

import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const fetchListStart = () => ({
  type: Types.FETCH_LIST_START,
});

export const fetchListEnd = () => ({
  type: Types.FETCH_LIST_END,
});

export const setList = list => ({
  type: Types.SET_LIST,
  payload: list,
});

export default ({ schoolId, requestParams = { limit: -1 }, callback }) =>
  async dispatch => {
    try {
      dispatch(fetchListStart());
      const {
        data: { data, totalCount },
      } = await schoolsServices.getSchoolCourseTemplates({
        schoolId,
        params: requestParams,
      });
      dispatch(setList(data));
      if (callback) callback({ totalCount, data });
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(fetchListEnd());
    }
  };
