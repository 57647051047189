import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import styles from './styles';

const useStyles = makeStyles(styles);

function ColigoLoading() {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.book}>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default ColigoLoading;
