import Types from '../types';
import { getCurrentUser } from '../../services/authService';

const INITIAL_STATE = {
  // FIXME: this shouldn't be a function, currently getCurrentUser can be async
  // which results in problems.
  user: getCurrentUser(),
  errorMessage: '',
  isAuthenticatingUser: false,
};

export default function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.AUTH_USER:
      return { ...state, user: action.payload };
    case Types.AUTH_ERROR:
      return { ...state, user: {}, errorMessage: action.payload };
    case Types.USER_LOGOUT:
      return INITIAL_STATE;
    case Types.AUTH_USER_START:
      return { ...state, isAuthenticatingUser: true };
    case Types.AUTH_USER_END:
      return { ...state, isAuthenticatingUser: false };
    default:
      return state;
  }
}
