import { schools as schoolsServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const createItemStart = () => ({
  type: Types.CREATE_ITEM_START,
});

export const createItemEnd = () => ({
  type: Types.CREATE_ITEM_END,
});

export default ({
    schoolId,
    data: { thumbnailFile, ...otherProps },
    onUploadProgress,
    callback,
  }) =>
  async dispatch => {
    try {
      dispatch(createItemStart());
      const payload = { ...otherProps };

      if (thumbnailFile) {
        const formData = new FormData();
        formData.append('image', thumbnailFile);
        const { data } = await schoolsServices.uploadThumbnail({
          schoolId,
          data: formData,
          onUploadProgress,
        });
        payload.thumbnail = data.url;
      }

      await schoolsServices.createCourse({ schoolId, data: payload });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(createItemEnd());
    }
  };
