import { combineReducers } from 'redux';

import activateSemester from './actions/activateSemester';
import archiveSemester from './actions/archiveSemester';
import lockSemester from './actions/lockSemester';
import state from './reducers/state';
import updateSemester from './actions/updateSemester';
import copyCourses from './actions/copyCourses';

export const SemestersActions = {
  activateSemester,
  archiveSemester,
  lockSemester,
  updateSemester,
  copyCourses,
};

export default combineReducers({
  state,
});
