import Types from '../types';

const initialState = {
  isGettingItem: false,
  isUpdatingGradableItem: false,
  isDeletingGradableItem: false,
  isDeletingMaterial: false,
  isCreatingMaterial: false,
  isUpdatingMaterial: false,
  isGettingMaterial: false,
  isShallowFetchingMaterials: false,
  isGettingGradebookPolicy: false,
  isFetchingGradableItems: false,
  isCreatingGradableItem: false,
  isFetchingGradableItem: false,
  isGettingSemesters: false,
  isGettingCourses: false,
  isCreatingAssessment: false,
  isGettingAssessments: false,
  isGettingClasswork: false,
  isSubmittingAssessment: false,
  isUnSubmittingAssessment: false,
  isDeletingAssessment: false,
  isCancelingAssessment: false,
  isGettingAssessment: false,
  isCopyingAssessment: false,
  isRestoringAssessment: false,
  autoSaveState: 'initialState',
  isGettingSubmissions: false,
  isSubmittingSubmission: false,
  isGettingFolderContent: false,
  isGettingMaterials: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.GET_SEMESTERS_START:
      return {
        ...reducerState,
        isGettingSemesters: true,
      };

    case Types.GET_SEMESTERS_END:
      return {
        ...reducerState,
        isGettingSemesters: false,
      };

    case Types.GET_COURSE_START:
      return {
        ...reducerState,
        isGettingItem: true,
      };

    case Types.GET_COURSE_END:
      return {
        ...reducerState,
        isGettingItem: false,
      };

    case Types.CREATE_MATERIAL_START:
      return {
        ...reducerState,
        isCreatingMaterial: true,
      };

    case Types.CREATE_MATERIAL_END:
      return {
        ...reducerState,
        isCreatingMaterial: false,
      };

    case Types.GET_COURSE_MATERIALS_START:
      return {
        ...reducerState,
        isGettingMaterials: true,
      };

    case Types.GET_COURSE_MATERIALS_END:
      return {
        ...reducerState,
        isGettingMaterials: false,
      };

    case Types.DELETE_MATERIAL_START:
      return {
        ...reducerState,
        isDeletingMaterial: true,
      };

    case Types.DELETE_MATERIAL_END:
      return {
        ...reducerState,
        isDeletingMaterial: false,
      };

    case Types.UPDATE_MATERIAL_START:
      return {
        ...reducerState,
        isUpdatingMaterial: true,
      };

    case Types.UPDATE_MATERIAL_END:
      return {
        ...reducerState,
        isUpdatingMaterial: false,
      };

    case Types.GET_MATERIAL_START:
      return {
        ...reducerState,
        isGettingMaterial: true,
      };

    case Types.GET_MATERIAL_END:
      return {
        ...reducerState,
        isGettingMaterial: false,
      };

    case Types.SHALLOW_GET_COURSE_MATERIALS_START:
      return {
        ...reducerState,
        isShallowFetchingMaterials: true,
      };

    case Types.SHALLOW_GET_COURSE_MATERIALS_END:
      return {
        ...reducerState,
        isShallowFetchingMaterials: false,
      };

    case Types.CREATE_GRADABLE_ITEM_START:
      return {
        ...reducerState,
        isCreatingGradableItem: true,
      };

    case Types.CREATE_GRADABLE_ITEM_END:
      return {
        ...reducerState,
        isCreatingGradableItem: false,
      };

    case Types.COPY_GRADABLE_ITEM_START:
      return {
        ...reducerState,
        isCreatingGradableItem: true,
      };

    case Types.COPY_GRADABLE_ITEM_END:
      return {
        ...reducerState,
        isCreatingGradableItem: false,
      };

    case Types.GET_GRADEBOOK_POLICY_START:
      return {
        ...reducerState,
        isGettingGradebookPolicy: true,
      };

    case Types.GET_GRADEBOOK_POLICY_END:
      return {
        ...reducerState,
        isGettingGradebookPolicy: false,
      };

    case Types.GET_GRADABLE_ITEMS_START:
      return {
        ...reducerState,
        isFetchingGradableItems: true,
      };

    case Types.GET_GRADABLE_ITEMS_END:
      return {
        ...reducerState,
        isFetchingGradableItems: false,
      };

    case Types.UPDATE_GRADABLE_ITEM_START:
      return {
        ...reducerState,
        isUpdatingGradableItem: true,
      };

    case Types.UPDATE_GRADABLE_ITEM_END:
      return {
        ...reducerState,
        isUpdatingGradableItem: false,
      };

    case Types.DELETE_GRADABLE_ITEM_START:
      return {
        ...reducerState,
        isDeletingGradableItem: true,
      };

    case Types.DELETE_GRADABLE_ITEM_END:
      return {
        ...reducerState,
        isDeletingGradableItem: false,
      };
    case Types.GET_GRADABLE_ITEM_START:
      return {
        ...reducerState,
        isFetchingGradableItem: true,
      };

    case Types.GET_GRADABLE_ITEM_END:
      return {
        ...reducerState,
        isFetchingGradableItem: false,
      };

    case Types.CREATE_ASSESSMENT_START:
      return {
        ...reducerState,
        isCreatingAssessment: true,
      };

    case Types.CREATE_ASSESSMENT_END:
      return {
        ...reducerState,
        isCreatingAssessment: false,
      };
    case Types.GET_ASSESSMENTS_START:
      return {
        ...reducerState,
        isGettingAssessments: true,
      };
    case Types.GET_ASSESSMENTS_END:
      return {
        ...reducerState,
        isGettingAssessments: false,
      };
    case Types.GET_CLASSWORK_START:
      return {
        ...reducerState,
        isGettingClasswork: true,
      };
    case Types.GET_CLASSWORK_END:
      return {
        ...reducerState,
        isGettingClasswork: false,
      };
    case Types.SUBMIT_ASSESSMENT_START:
      return {
        ...reducerState,
        isSubmittingAssessment: true,
      };
    case Types.SUBMIT_ASSESSMENT_END:
      return {
        ...reducerState,
        isSubmittingAssessment: false,
      };
    case Types.UNSUBMIT_ASSESSMENT_START:
      return {
        ...reducerState,
        isUnSubmittingAssessment: true,
      };
    case Types.UNSUBMIT_ASSESSMENT_END:
      return {
        ...reducerState,
        isUnSubmittingAssessment: false,
      };
    case Types.DELETE_ASSESSMENT_START:
      return {
        ...reducerState,
        isDeletingAssessment: true,
      };
    case Types.DELETE_ASSESSMENT_END:
      return {
        ...reducerState,
        isDeletingAssessment: false,
      };
    case Types.CANCEL_ASSESSMENT_START:
      return {
        ...reducerState,
        isCancelingAssessment: true,
      };
    case Types.CANCEL_ASSESSMENT_END:
      return {
        ...reducerState,
        isCancelingAssessment: false,
      };
    case Types.GET_ASSESSMENT_START:
      return {
        ...reducerState,
        isGettingAssessment: true,
      };
    case Types.GET_ASSESSMENT_END:
      return {
        ...reducerState,
        isGettingAssessment: false,
      };
    case Types.COPY_ASSESSMENT_START:
      return {
        ...reducerState,
        isCopyingAssessment: true,
      };
    case Types.COPY_ASSESSMENT_END:
      return {
        ...reducerState,
        isCopyingAssessment: false,
      };
    case Types.RESTORE_ASSESSMENT_START:
      return {
        ...reducerState,
        isRestoringAssessment: true,
      };
    case Types.RESTORE_ASSESSMENT_END:
      return {
        ...reducerState,
        isRestoringAssessment: false,
      };
    case Types.CHANGE_AUTOSAVE_STATE:
      return {
        ...reducerState,
        autoSaveState: action.payload,
      };
    case Types.GET_ASSESSMENT_SUBMISSIONS_START:
      return {
        ...reducerState,
        isGettingSubmissions: true,
      };
    case Types.GET_ASSESSMENT_SUBMISSIONS_END:
      return {
        ...reducerState,
        isGettingSubmissions: false,
      };
    case Types.SUBMIT_SUBMISSION_START:
      return {
        ...reducerState,
        isSubmittingSubmission: true,
      };
    case Types.SUBMIT_SUBMISSION_END:
      return {
        ...reducerState,
        isSubmittingSubmission: false,
      };
    case Types.GET_FOLDER_CONTENT_START:
      return {
        ...reducerState,
        isGettingFolderContent: true,
      };
    case Types.GET_FOLDER_CONTENT_END:
      return {
        ...reducerState,
        isGettingFolderContent: false,
      };
    default:
      return reducerState;
  }
};
