import { ErrorsActions } from '../error';
import { coursesTypes } from '../../reducers/types';

const setCourseData = course => ({
  type: coursesTypes.SET_COURSE,
  payload: course,
});

export default course => async dispatch => {
  try {
    dispatch(setCourseData(course));
  } catch (error) {
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  }
};
