import manaratSchoolLogo from './manarat/logo.jpeg';

const CUSTOM_SCHOOLS_DATA = {
  MANARAT: {
    name: 'Manarat Schools',
    arabicName: 'مدارس منارات',
    logo: manaratSchoolLogo,
  },
};

const getCustomSchoolData = () => {
  const school = window.location.hostname.split('.')[0].toUpperCase();
  if (school in CUSTOM_SCHOOLS_DATA) {
    return CUSTOM_SCHOOLS_DATA[school];
  }
  return null;
};

export default getCustomSchoolData;
