import { combineReducers } from 'redux';
import addItem from './actions/addItem';
import createItem from './actions/createItem';
import deleteItem from './actions/deleteItem';

import state from './reducers/state';

export const SubjectsActions = {
  addItem,
  createItem,
  deleteItem,
};

export default combineReducers({
  state,
});
