import Types from '../types';

const initialState = {
  isLoadingConstants: false,
  isChangingUserRole: false,
  isSwitchingAccount: false,
  isLoadingFeatureFlags: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_USER_START:
      return {
        ...reducerState,
        isChangingUserRole: true,
      };
    case Types.UPDATE_USER_END:
      return {
        ...reducerState,
        isChangingUserRole: false,
      };
    case Types.SWITCH_USER_START:
      return {
        ...reducerState,
        isSwitchingAccount: true,
      };
    case Types.SWITCH_USER_END:
      return {
        ...reducerState,
        isSwitchingAccount: false,
      };
    case Types.LOAD_FEATURE_FLAGS_START:
      return {
        ...reducerState,
        isLoadingFeatureFlags: true,
      };
    case Types.LOAD_FEATURE_FLAGS_END:
      return {
        ...reducerState,
        isLoadingFeatureFlags: false,
      };
    default:
      return reducerState;
  }
};
