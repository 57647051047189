import {
  assessments as AssessmentsServices,
  homeworks as HomeworksServices,
} from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const unSubmitAssessmentStart = () => ({
  type: coursesTypes.UNSUBMIT_ASSESSMENT_START,
});

export const unSubmitAssessmentEnd = () => ({
  type: coursesTypes.UNSUBMIT_ASSESSMENT_END,
});

const unSubmitAssessment = (isHomework, assessmentId) =>
  isHomework
    ? HomeworksServices.unSubmitHomework({ homeworkId: assessmentId })
    : AssessmentsServices.unSubmitAssessment({ assessmentId });

export default (
    { assessmentId, callback, isHomework } = { isHomework: false },
  ) =>
  async dispatch => {
    try {
      dispatch(unSubmitAssessmentStart());
      await unSubmitAssessment(isHomework, assessmentId);
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(unSubmitAssessmentEnd());
    }
  };
