/* eslint-disable no-console */
function init() {}

function log(error) {
  console.error(error);
}

function info(notification) {
  console.info(notification);
}

export default {
  init,
  log,
  info,
};
