import React, { useState } from 'react';
import { users as usersServices } from '@coligo-org/fe-common/services';
import { PropTypes } from 'prop-types';
import { regex } from '@coligo-org/fe-common/constants';

import { Paper, Typography, TextField, Tooltip, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { toast } from 'react-toastify';
import styles from './styles';

const useStyles = makeStyles(styles);

function Request({ backToLogin, goToChooseAccount, goToPending }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [noAccountsWithEmail, setNoAccountsWithEmail] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const classes = useStyles();

  const validate = mail => {
    if (mail && !regex.IS_EMAIL.test(mail)) {
      setError('email must be like this example@mail.com');
      setIsValidEmail(false);
      return;
    }
    if (!mail) {
      setError('required');
      setIsValidEmail(false);
      return;
    }
    setIsValidEmail(true);
  };

  const getEmailAccounts = async () => {
    try {
      const { data } = await usersServices.getUserEmail({ userEmail: email });
      if (data.length === 0) {
        setNoAccountsWithEmail(true);
      } else if (data.length > 1) {
        goToChooseAccount(data);
      } else {
        goToPending(data[0]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" className={classes.title}>
        Enter your verified email
      </Typography>
      <TextField
        variant="standard"
        placeholder="Example@example.com"
        className={classes.input}
        onChange={e => {
          setNoAccountsWithEmail(false);
          setEmail(e.target.value);
        }}
        onBlur={e => {
          validate(e.target.value);
        }}
      />
      <Typography
        variant="caption"
        className={`${classes.error} ${isValidEmail && classes.hidden}`}
      >
        {error}
      </Typography>
      <Typography
        variant="body2"
        className={`${classes.notVerifiedEmail} ${
          !noAccountsWithEmail && classes.hidden
        }`}
      >
        No such verified email
        <Tooltip title="If you don't have a verified email please contact your admin to reset your password">
          <InfoIcon className={classes.InfoIcon} />
        </Tooltip>
      </Typography>
      <Button
        className={classes.button}
        onClick={() => {
          if (isValidEmail) getEmailAccounts();
          validate(email);
        }}
      >
        Continue
      </Button>
      <Typography variant="caption" className={classes.caption}>
        Back to{' '}
        <Typography
          variant="caption"
          className={classes.hyperLink}
          onClick={() => {
            backToLogin();
          }}
        >
          Login
        </Typography>
      </Typography>
    </Paper>
  );
}

Request.propTypes = {
  backToLogin: PropTypes.func.isRequired,
  goToChooseAccount: PropTypes.func.isRequired,
  goToPending: PropTypes.func.isRequired,
};
export default Request;
