import Types from '../types';

const initialState = {
  isLoadingList: false,
  isLoadingItem: false,
  isCreatingItem: false,
  isUpdatingItem: false,
  isDeletingItem: false,
  isCreatingAdmin: false,
  isDeletingAdmin: false,
  isDeactivatingItem: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_LIST_START:
      return {
        ...reducerState,
        isLoadingList: true,
      };

    case Types.FETCH_ITEM_START:
      return {
        ...reducerState,
        isLoadingItem: true,
      };

    case Types.CREATE_ITEM_START:
      return {
        ...reducerState,
        isCreatingItem: true,
      };

    case Types.UPDATE_ITEM_START:
      return {
        ...reducerState,
        isSavingItem: true,
      };

    case Types.DELETE_ITEM_START:
      return {
        ...reducerState,
        isDeletingItem: true,
      };
    case Types.CREATE_ADMIN_START:
      return {
        ...reducerState,
        isCreatingAdmin: true,
      };
    case Types.DELETE_ADMIN_START:
      return {
        ...reducerState,
        isDeletingAdmin: true,
      };

    case Types.DEACTIVATE_ITEM_START:
      return {
        ...reducerState,
        isDeactivatingItem: true,
      };

    case Types.DEACTIVATE_ITEM_END:
      return {
        ...reducerState,
        isDeactivatingItem: false,
      };
    case Types.FETCH_LIST_END:
    case Types.FETCH_ITEM_END:
    case Types.CREATE_ITEM_END:
    case Types.UPDATE_ITEM_END:
    case Types.DELETE_ITEM_END:
    case Types.CREATE_ADMIN_END:
    case Types.DELETE_ADMIN_END:
      return initialState;

    default:
      return reducerState;
  }
};
