import { users as usersServices } from '@coligo-org/fe-common/services';
import logger from '../../../common/services/logService';
import { ErrorsActions } from '../error';
import { questionBankTypes } from '../../reducers/types';

export const getQuestionsStart = () => ({
  type: questionBankTypes.GET_QUESTIONS_START,
});

export const getQuestionsEnd = () => ({
  type: questionBankTypes.GET_QUESTIONS_END,
});

export default ({ hodId, requestParams, callback }) =>
  async dispatch => {
    try {
      dispatch(getQuestionsStart());
      const { data } = await usersServices.getHodQuestions({
        hodId,
        params: requestParams,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getQuestionsEnd());
    }
  };
