import { courses as CoursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getAssessmentsStart = () => ({
  type: coursesTypes.GET_ASSESSMENTS_START,
});

export const getAssessmentsEnd = () => ({
  type: coursesTypes.GET_ASSESSMENTS_END,
});

export const setCourseAssessments = courseAssessments => ({
  type: coursesTypes.SET_COURSE_ASSESSMENTS,
  payload: courseAssessments,
});

export const setCourseHomework = courseHomeworks => ({
  type: coursesTypes.SET_COURSE_HOMEWORKS,
  payload: courseHomeworks,
});

export default ({ courseId, callback }) =>
  async dispatch => {
    try {
      dispatch(getAssessmentsStart());
      const [{ data: assessments }, { data: homeworks }] = await Promise.all([
        CoursesServices.getCourseAssessments({ courseId }),
        CoursesServices.getCourseHomeworks({ courseId }),
      ]);
      dispatch(setCourseAssessments(assessments));
      dispatch(setCourseHomework(homeworks));
      if (callback) callback([...assessments, ...homeworks]);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getAssessmentsEnd());
    }
  };
