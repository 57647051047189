import { courses as CoursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getCourseMaterialsStart = () => ({
  type: coursesTypes.GET_COURSE_MATERIALS_START,
});

export const getCourseMaterialsEnd = () => ({
  type: coursesTypes.GET_COURSE_MATERIALS_END,
});

export const setCourseMaterials = courseMaterials => ({
  type: coursesTypes.SET_COURSE_MATERIALS,
  payload: courseMaterials,
});

export default courseId => async dispatch => {
  try {
    dispatch(getCourseMaterialsStart());
    const { data } = await CoursesServices.getCourseMaterials({ courseId });
    dispatch(setCourseMaterials(data));
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(getCourseMaterialsEnd());
  }
};
