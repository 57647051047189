import Types from '../types';

const initialState = {
  isUpdatingUser: false,
  isGettingUser: false,
  isGettingUserCourses: false,
  isGettingPrincipalClasses: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_USER_START:
      return {
        ...reducerState,
        isUpdatingUser: true,
      };
    case Types.GET_USER_START:
      return {
        ...reducerState,
        isGettingUser: true,
      };

    case Types.GET_USER_END:
      return {
        ...reducerState,
        isGettingUser: false,
      };

    case Types.UPDATE_USER_END:
      return {
        ...reducerState,
        isUpdatingUser: false,
      };

    case Types.GET_USER_COURSES_LIST_START:
      return {
        ...reducerState,
        isGettingUserCourses: true,
      };

    case Types.GET_USER_COURSES_LIST_END:
      return {
        ...reducerState,
        isGettingUserCourses: false,
      };
    case Types.GET_PRINCIPAL_CLASSES_START:
      return {
        ...reducerState,
        isGettingPrincipalClasses: true,
      };
    case Types.GET_PRINCIPAL_CLASSES_END:
      return {
        ...reducerState,
        isGettingPrincipalClasses: false,
      };
    default:
      return reducerState;
  }
};
