import { combineReducers } from 'redux';

import updateMessage from './actions/updateMessage';
import restoreMessage from './actions/restoreMessage';
import fetchMessages from './actions/fetchMessages';
import fetchAcademicYearCourses from './actions/fetchAcademicYearCourses';
import fetchUsers from './actions/fetchUsers';
import fetchUnreadCount from './actions/fetchUnreadCount';
import * as viewAsActions from './actions/viewAsActions';

import list from './reducers/list';
import unreadCount from './reducers/unread-count';
import courses from './reducers/courses';
import users from './reducers/users';
import state from './reducers/state';
import previewMode from './reducers/preview-mode';
import totalCount from './reducers/total-count';

export const MessagesActions = {
  updateMessage,
  fetchAcademicYearCourses,
  fetchUsers,
  fetchMessages,
  fetchUnreadCount,
  restoreMessage,
  ...viewAsActions,
};

export default combineReducers({
  list,
  courses,
  users,
  state,
  unreadCount,
  totalCount,
  previewMode,
});
