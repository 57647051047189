import Types from '../types';

const activateViewAsMode = user => ({
  type: Types.ACTIVATE_VIEW_AS_MODE,
  payload: user,
});
const cancelViewAsMode = () => ({
  type: Types.CANCEL_VIEW_AS_MODE,
});

export { activateViewAsMode, cancelViewAsMode };
