import Types from '../types';

const initialState = {
  isGettingGradebookCertificate: false,
  isGettingGradebookSummary: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.GET_GRADEBOOK_CERTIFICATE_START:
      return {
        ...reducerState,
        isGettingGradebookCertificate: true,
      };

    case Types.GET_GRADEBOOK_CERTIFICATE_END:
      return {
        ...reducerState,
        isGettingGradebookCertificate: false,
      };

    case Types.GET_GRADEBOOK_SUMMARY_START:
      return {
        ...reducerState,
        isGettingGradebookSummary: true,
      };

    case Types.GET_GRADEBOOK_SUMMARY_END:
      return {
        ...reducerState,
        isGettingGradebookSummary: false,
      };

    default:
      return reducerState;
  }
};
