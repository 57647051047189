import { courses as CoursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getCourseStart = () => ({
  type: coursesTypes.GET_COURSE_START,
});

export const getCourseEnd = () => ({
  type: coursesTypes.GET_COURSE_END,
});

export const setCourse = course => ({
  type: coursesTypes.SET_COURSE,
  payload: course,
});

export default courseId => async dispatch => {
  try {
    dispatch(getCourseStart());
    const { data } = await CoursesServices.getCourseInfo({ courseId });
    dispatch(setCourse(data));
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(getCourseEnd());
  }
};
