import Types from '../types';

const INITIAL_STATE = {
  active: false,
  previewAs: {},
};

export default (reducerState = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.ACTIVATE_VIEW_AS_MODE:
      return {
        active: true,
        previewAs: action.payload,
      };
    case Types.CANCEL_VIEW_AS_MODE:
      return INITIAL_STATE;
    default:
      return reducerState;
  }
};
