import Types from '../types';

export default (reducerState = [], action) => {
  switch (action.type) {
    case Types.SET_COURSES:
      return action.payload;
    default:
      return reducerState;
  }
};
