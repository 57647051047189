import amplitude from 'amplitude-js';

const Analytics = {
  init({ token = '' }) {
    this.driver.getInstance().init(token);
  },

  track({ eventName, data, cb }) {
    this.driver.getInstance().logEvent(eventName, data, cb);
  },

  identify({ id }) {
    this.driver.getInstance().setUserId(id);
  },

  setUserProperties(userProperties) {
    this.driver.getInstance().setUserProperties(userProperties);
  },

  driver: amplitude,
};

export default Analytics;
