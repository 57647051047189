import { jwtDecode } from 'jwt-decode';
import { auth, http, logger, common } from '@coligo-org/fe-common/services';
import constants, { STORAGE_KEYS } from '@coligo-org/fe-common/constants';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

import Analytics from '../../common/helpers/analytics';

let refreshTokenPromise;
let controller = new AbortController();
let { signal } = controller;

const userKey = STORAGE_KEYS.STRINGIFIED_USER_DATA_KEY;
auth.init(localStorage);
export const { login, loginWithJwt, getJwt, logout } = auth;

// TODO: remove this function and make related refactors
export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(STORAGE_KEYS.TOKEN_KEY);
    const userObject = localStorage.getItem(userKey);

    const user = { ...jwtDecode(jwt), ...JSON.parse(userObject) };
    Analytics.identify({ id: user && user._id });
    return user;
  } catch (ex) {
    return {};
  }
};

const refreshJWT = async () => {
  const { username: userName } = JSON.parse(localStorage.getItem(userKey));

  try {
    await auth.refreshToken({ userName, signal });
    controller.abort();
  } finally {
    refreshTokenPromise = null;
    controller = new AbortController();
    signal = controller.signal;
  }
};

const responseErrorInterceptor = async error => {
  switch (error.response?.data?.errorCode) {
    case constants.ERROR_CODES.INVALID_TOKEN: {
      // if invalid token comes from resetting password don't redirect to login
      if (error.response?.config?.url.contains('password/reset')) return null;
      // if user refresh token is invalid
      // logout silently since you're not authorized anymore
      await logout(true);
      window.location.replace('/logout?&expired=1');
      return Promise.reject(error);
    }
    case constants.ERROR_CODES.JWT_EXPIRED: {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshJWT();
      }
      // refreshTokenPromise => currently running refresh jwt call
      try {
        // since it's the same instance of the promise would only called one time
        // after refreshJWT promise resolved refreshTokenPromise would assigned to null
        try {
          await refreshTokenPromise;
        } catch (err) {
          // if the refreshToken failed then you need to logout
          // that means that the refreshTokenId is not valid and the user has not other option
          Sentry.withScope(scope => {
            scope.setExtra('isInternal', true);
            Sentry.captureMessage('Error in refreshing token');
            Sentry.captureMessage(...error.config);
            Sentry.captureException(err);
          });
          window.location.replace('/logout');
        }
        const interceptedRequestWithRefreshedJWT = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `JWT ${await getJwt()}`,
          },
        };
        return http.request(interceptedRequestWithRefreshedJWT);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    case constants.ERROR_CODES.DEACTIVATED_USER: {
      if (!window.location.href.includes('login')) {
        // logout without deleting you refresh token from server, since you're no longer authorized
        await logout(true);
        window.location.replace('/logout?deactivated=1');
      }
      logger.error(error);
      return Promise.reject(error);
    }
    default: {
      const expectedError =
        error.response?.status >= 400 && error.response?.status < 500;

      if (!expectedError) {
        if (error.message === 'canceled') {
          logger.log('canceled');
          return null;
        }
        if (!error.config?.data) {
          toast.info('Network error, please check your internet access');
        } else {
          toast.error('An unexpected error occurred.');
        }
      }
      return Promise.reject(error);
    }
  }
};

http.addResponseInterceptor({
  interceptorName: common.RESPONSE_INTERCEPTORS_NAMES.HANDLE_GENERAL_ERRORS,
  errorInterceptor: responseErrorInterceptor,
});

http.addRequestInterceptor({
  interceptorName: 'Add ',
  errorInterceptor: responseErrorInterceptor,
});

export default {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
