import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

export default ChildComponent => {
  function ComposedComponent(props) {
    const { user } = props;
    return Object.keys(user).length === 0 ? (
      <Navigate to="/login" />
    ) : (
      <ChildComponent {...props} />
    );
  }

  const mapStateToProps = state => ({
    user: state.auth.user,
  });

  ComposedComponent.propTypes = {
    user: PropTypes.shape().isRequired,
  };

  return connect(mapStateToProps)(ComposedComponent);
};
