const AUTH_USER_START = 'AUTH_USER_START';
const AUTH_USER_END = 'AUTH_USER_END';
const AUTH_USER = 'AUTH_USER';
const AUTH_ERROR = 'AUTH_ERROR';
const USER_LOGOUT = 'USER_LOGOUT';

export default {
  AUTH_USER_START,
  AUTH_USER_END,
  AUTH_USER,
  AUTH_ERROR,
  USER_LOGOUT,
};
