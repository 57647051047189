import Types from '../types';

const initialState = {
  isLoadingItem: false,
  isCreatingItem: false,
  isDeletingItem: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.GET_ITEM_START:
      return {
        ...reducerState,
        isLoadingItem: true,
      };
    case Types.GET_ITEM_END:
      return {
        ...reducerState,
        isLoadingItem: false,
      };

    case Types.CREATE_ITEM_START:
      return {
        ...reducerState,
        isCreatingItem: true,
      };

    case Types.CREATE_ITEM_END:
      return {
        ...reducerState,
        isCreatingItem: false,
      };

    case Types.DELETE_ITEM_START:
      return {
        ...reducerState,
        isDeletingItem: true,
      };
    case Types.DELETE_ITEM_END:
      return {
        ...reducerState,
        isDeletingItem: false,
      };

    default:
      return reducerState;
  }
};
