import { gradebookSystems as gradebookSystemsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../../../../common/error/state';
import logger from '../../../../../../../../../../common/services/logService';
import Types from '../types';

export const fetchListStart = () => ({
  type: Types.FETCH_GRADING_SYSTEMS_START,
});

export const fetchListEnd = () => ({
  type: Types.FETCH_GRADING_SYSTEMS_END,
});

export const setList = list => ({
  type: Types.SET_GRADING_SYSTEMS,
  payload: list,
});

export default () => async dispatch => {
  try {
    dispatch(fetchListStart());
    const { data } = await gradebookSystemsService.getGradingSystems();
    dispatch(setList(data));
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(fetchListEnd());
  }
};
