import Types from '../types';

const INITIAL_STATE = 0;

export default (reducerState = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_UNREAD_COUNT:
      return action.payload;
    default:
      return reducerState;
  }
};
