import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { paymentMessages } from '@coligo-org/fe-common/translations';
import { STORAGE_KEYS } from '@coligo-org/fe-common/constants';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import CreditCardImage from 'common/assets/img/creditcard.png';
import styles from './styles';

function PaymobCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = makeStyles(styles)();

  const params = new URLSearchParams(location.search);
  const isSuccess = params.get('success') === 'true';

  const isFailed = params.get('success') === 'false';

  if (localStorage.getItem(STORAGE_KEYS.PAYMOB_URL) !== null) {
    localStorage.removeItem(STORAGE_KEYS.PAYMOB_URL);
  }

  return (
    <main className={classes.main}>
      <section className={classes.container}>
        {isSuccess ? (
          <figure>
            <img src={CreditCardImage} alt="credit card" />
          </figure>
        ) : (
          <CreditCardOffIcon color="error" className={classes.iconError} />
        )}

        {isSuccess ? (
          <>
            <Typography color="primary" variant="h3" className={classes.title}>
              <CheckIcon />
              <span>
                <FormattedMessage
                  {...paymentMessages.paymentSuccessfullyPaid}
                />
              </span>
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              <FormattedMessage
                {...paymentMessages.paymentSuccessfullyPaidMessage}
              />

              <br />
              <FormattedMessage
                {...paymentMessages.paymentCheckPaymentMessage}
              />
            </Typography>
          </>
        ) : (
          <>
            <Typography color="error" variant="h3" className={classes.title}>
              <HighlightOffIcon color="error" />
              <span>
                {isFailed ? (
                  <FormattedMessage {...paymentMessages.paymentFailed} />
                ) : (
                  'No payment found'
                )}
              </span>
            </Typography>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {isFailed ? (
                <FormattedMessage {...paymentMessages.paymentFailedMessage} />
              ) : (
                'Please try again later'
              )}
            </Typography>
          </>
        )}
        <Button
          variant="contained"
          className={classes.btn}
          onClick={() => {
            navigate('/', { replace: true });
          }}
        >
          <FormattedMessage {...paymentMessages.paymentDashboardButton} />
        </Button>
      </section>
    </main>
  );
}

export default PaymobCallback;
