import { materials as MaterialsService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';
import getCourseMaterials from './getCourseMaterials';

export const editMaterialStart = () => ({
  type: coursesTypes.UPDATE_MATERIAL_START,
});

export const editMaterialEnd = () => ({
  type: coursesTypes.UPDATE_MATERIAL_END,
});

export default ({ courseId, materialId, material, callback }) =>
  async dispatch => {
    try {
      dispatch(editMaterialStart());
      await MaterialsService.editMaterial({ materialId, material });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(editMaterialEnd());
      dispatch(getCourseMaterials(courseId));
    }
  };
