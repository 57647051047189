import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import constants from '@coligo-org/fe-common/constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

import 'react-toastify/dist/ReactToastify.css';
import { auth as authService, http } from '@coligo-org/fe-common/services';
import translations from '@coligo-org/fe-common/translations';
import PaymobCallback from 'app/modules/common/payment/views/PaymobCallback';
import App from './app/App';
import Login from './auth/components/Login/Login';
import Logout from './auth/components/Logout/Logout';
import NotFound from './common/components/NotFound/NotFound';

import configureStore from './reduxConfig/config/store';
import * as serviceWorker from './serviceWorker';

import Analytics from './common/helpers/analytics';
import './common/assets/css/appStyle.css';
import 'react-table/react-table.css';

import theme from './common/assets/theme';
import Reset from './auth/components/ForgotPassword/Reset';

authService.init(localStorage); // handle async await in auth service file
http.setCustomHeader('Application', 'web');
const reduxStore = configureStore(window.REDUX_INITIAL_STATE);

Analytics.init({ token: process.env.REACT_APP_AMPLITUDE_TOKEN });

const trackPageVisit = (location, lastPath) => {
  const path = location.pathname
    .replace(/[a-f\d]{24}/gi, ':id')
    .replace(/\/\//, '/')
    .replace(/\/$/, '');

  if (path !== lastPath) {
    const pageName = path || 'Home';

    // ==================== ANALYTICS ====================
    Analytics.track({
      eventName: `visited ${pageName}`,
      data: { originalPath: location.pathname },
    });
    // ==================== ANALYTICS ====================
  }

  return path;
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_TOKEN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  beforeSend: err => {
    if (err?.extra?.isInternal) {
      return err;
    }
    // escape any external (from node modules) errors and only log fetal errors from errorBoundary
    return null;
  },
});
let lastPath;

function AppWrapper() {
  const { locale } = useSelector(state => state.i18n.lang);
  const location = useLocation();

  useEffect(() => {
    // TODO: check the logic comparing to the old one after updating the whole app
    lastPath = trackPageVisit(location, lastPath);
  }, [location]);

  return (
    <IntlProvider
      messages={translations[locale]}
      locale={locale}
      defaultLocale="en"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<Reset />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/payment-callback" element={<PaymobCallback />} />
          <Route path="/*" element={<App />} />
        </Routes>
      </LocalizationProvider>
    </IntlProvider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: false,
      staleTime: constants.TIME.MS_IN_DAY,
    },
  },
});
const isCypress = Boolean(window.Cypress);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={!isCypress} />
    <Provider store={reduxStore}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <AppWrapper />
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </QueryClientProvider>,
);
serviceWorker.unregister();
