import { classes as ClassesService } from '@coligo-org/fe-common/services';
import Types from '../types';
import logger from '../../../../../../../common/services/logService';
import { ErrorsActions } from '../../../../../common/error/state';

export const updateItemStart = () => ({
  type: Types.UPDATE_ITEM_START,
});

export const updateItemEnd = () => ({
  type: Types.UPDATE_ITEM_END,
});

export default ({ classId, data, callback }) =>
  async dispatch => {
    try {
      dispatch(updateItemStart());
      await ClassesService.updateItem({ classId, data });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateItemEnd());
    }
  };
