import { ERROR_CODES } from '@coligo-org/fe-common/constants';
import Types from '../types';
import logger from '../../../common/services/logService';
import { getCurrentUser, login, logout } from '../../services/authService';
import getDefaultRoute from '../../../app/helpers/getDefaultRoute';
import Analytics from '../../../common/helpers/analytics';

export const authenticateUserStart = () => ({
  type: Types.AUTH_USER_START,
});

export const authenticateUserEnd = () => ({
  type: Types.AUTH_USER_END,
});

export const refreshUserData = () => {
  const user = getCurrentUser();
  return {
    type: Types.AUTH_USER,
    payload: user,
  };
};

export const signIn =
  ({ username, password }, redirectCallBack, errorCallback, allRoles) =>
  async dispatch => {
    try {
      dispatch(authenticateUserStart());
      await login(username, password);
      const user = getCurrentUser();

      // ==================== ANALYTICS ====================
      const { firstName, middleName, lastName, avatar, email, phone } =
        user.profile;

      Analytics.identify({ id: user && user._id });
      Analytics.track({ eventName: 'User Login' });
      Analytics.setUserProperties({
        firstName,
        middleName,
        lastName,
        name: `${firstName}${
          (middleName && ` ${middleName}`) || ''
        } ${lastName}`,
        phone,
        email,
        avatar,
        username: user?.username,
        baseRole: user?.baseRole?.name,
      });
      // ==================== ANALYTICS ====================

      dispatch({
        type: Types.AUTH_USER,
        payload: user,
      });
      redirectCallBack(getDefaultRoute(user, allRoles));
    } catch (error) {
      logger.log(error);

      // ==================== ANALYTICS ====================
      Analytics.track({
        eventName: 'Login Failure',
        data: { error, username },
      });
      // ==================== ANALYTICS ====================

      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.errorCode === ERROR_CODES.DEACTIVATED_USER
      ) {
        errorCallback();
        error.message = 'Deactivated User';
      }
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.errorCode === ERROR_CODES.INVALID_CREDENTIALS
      ) {
        error.message = 'Invalid username or password';
      }
      dispatch({
        type: Types.AUTH_ERROR,
        payload: error.message,
      });
    } finally {
      dispatch(authenticateUserEnd());
    }
  };

export const signOut = ({ callback }) => {
  return async dispatch => {
    await logout();
    dispatch({
      type: Types.USER_LOGOUT,
    });
    if (callback) callback();
  };
};
