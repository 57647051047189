import { z } from 'zod';
import {
  questionBankMessages,
  moreMessages,
} from '@coligo-org/fe-common/translations';

export default intl =>
  z.object({
    username: z
      .string({
        required_error: intl.formatMessage({
          ...questionBankMessages.fieldRequiredError,
        }),
      })
      .nonempty(
        intl.formatMessage({
          ...questionBankMessages.fieldRequiredError,
        }),
      ),
    password: z
      .string({
        required_error: intl.formatMessage({
          ...questionBankMessages.fieldRequiredError,
        }),
      })
      .min(6, {
        message: intl.formatMessage({ ...moreMessages.passwordCharsErrorText }),
      })
      .nonempty(
        intl.formatMessage({
          ...questionBankMessages.fieldRequiredError,
        }),
      ),
  });
