import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { addMinutes } from 'date-fns';
import { users as usersServices } from '@coligo-org/fe-common/services';
import {
  profileSettingsMessages,
  commonMessages,
} from '@coligo-org/fe-common/translations';

import { Typography, Paper } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import requestPassSentImg from 'common/assets/img/requestPassSent.png';
import { CountDownTimer } from 'common/components';
import styles from './styles';

const useStyles = makeStyles(styles);

function Pending({ account }) {
  const [expiryTime, setExpiryTime] = useState(
    addMinutes(new Date().getTime(), 3),
  );
  const [canRequestResetPass, setCanRequestResetPass] = useState(false);

  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  const requestResetPass = useCallback(async () => {
    setExpiryTime(addMinutes(new Date().getTime(), 3));
    try {
      await usersServices.requestResetPassword({
        username: account.username,
        email: account.profile.email,
      });
    } catch (error) {
      setCanRequestResetPass(true);
      toast.error(error.message);
    }
  }, [account.profile.email, account.username]);

  const getFormattedEmail = () => {
    return (
      <Typography
        component="span"
        display="inline"
        className={classes.userEmail}
      >
        {account.profile.email}
      </Typography>
    );
  };

  useEffect(() => {
    requestResetPass();
  }, [requestResetPass]);

  return (
    <Paper className={classes.paper}>
      <img
        src={requestPassSentImg}
        alt="request-reset-sent"
        className={classes.requestPassSentImg}
      />
      <Typography component="span" className={classes.headerText}>
        {`Please follow the instructions mail sent to `}
        {getFormattedEmail()}
        {` inbox or spam/junk to reset your password.`}
      </Typography>
      <Typography
        component="span"
        display="inline"
        className={classes.subHeaderText}
      >
        {`${intl.formatMessage({
          ...profileSettingsMessages.resendEmailQuestion,
        })} `}
        {canRequestResetPass ? (
          <Typography
            component="span"
            display="inline"
            className={classes.hyperLink}
            onClick={() => {
              setCanRequestResetPass(false);
              requestResetPass();
            }}
          >
            {intl.formatMessage({
              ...commonMessages.resend,
            })}
          </Typography>
        ) : (
          <Typography
            component="span"
            display="inline"
            className={classes.subHeaderText}
          >
            {` ${intl.formatMessage({
              ...commonMessages.resend,
            })} ${intl.formatMessage({ ...commonMessages.in })} `}
            <CountDownTimer
              dueTime={expiryTime}
              onTimerEnd={() => {
                setCanRequestResetPass(true);
              }}
              withStyling={false}
            />
          </Typography>
        )}
      </Typography>
      <Typography
        component="span"
        display="inline"
        className={classes.subHeaderText}
      >
        {`Back to `}
        <Typography
          component="span"
          display="inline"
          className={classes.hyperLink}
          onClick={() => {
            navigate('', { replace: true });
          }}
        >
          login
        </Typography>
      </Typography>
    </Paper>
  );
}

Pending.propTypes = {
  account: PropTypes.shape({
    username: PropTypes.string.isRequired,
    profile: PropTypes.shape().isRequired,
  }).isRequired,
};

export default Pending;
