import { courses as CoursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';
import getCourseMaterials from './getCourseMaterials';

export const unshareMaterialStart = () => ({
  type: coursesTypes.UPDATE_MATERIAL_START,
});

export const unshareMaterialEnd = () => ({
  type: coursesTypes.UPDATE_MATERIAL_END,
});

export default ({ listCourseId, courseId, materialId, callback }) =>
  async dispatch => {
    try {
      dispatch(unshareMaterialStart());
      await CoursesServices.unshareMaterial({ courseId, materialId });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(unshareMaterialEnd());
      dispatch(
        getCourseMaterials(
          listCourseId === undefined ? courseId : listCourseId,
        ),
      );
    }
  };
