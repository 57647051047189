const moduleName = 'schools';
const SET_COUNT = `${moduleName}/SET_COUNT`;

const FETCH_ITEM_START = `${moduleName}/FETCH_ITEM_START`;
const FETCH_ITEM_END = `${moduleName}/FETCH_ITEM_END`;
const SET_ITEM = `${moduleName}/SET_ITEM`;

const FETCH_LIST_START = `${moduleName}/FETCH_LIST_START`;
const FETCH_LIST_END = `${moduleName}/FETCH_LIST_END`;
const SET_LIST = `${moduleName}/SET_LIST`;

const CREATE_ITEM_START = `${moduleName}/CREATE_ITEM_START`;
const CREATE_ITEM_END = `${moduleName}/CREATE_ITEM_END`;

const CREATE_ADMIN_START = `${moduleName}/CREATE_ADMIN_START`;
const CREATE_ADMIN_END = `${moduleName}/CREATE_ADMIN_END`;

const DELETE_ADMIN_START = `${moduleName}/DELETE_ADMIN_START`;
const DELETE_ADMIN_END = `${moduleName}/DELETE_ADMIN_END`;

const UPDATE_ITEM_START = `${moduleName}/UPDATE_ITEM_START`;
const UPDATE_ITEM_END = `${moduleName}/UPDATE_ITEM_END`;

const DELETE_ITEM_START = `${moduleName}/DELETE_ITEM_START`;
const DELETE_ITEM_END = `${moduleName}/DELETE_ITEM_END`;

const DEACTIVATE_ITEM_START = `${moduleName}/DEACTIVATE_ITEM_START`;
const DEACTIVATE_ITEM_END = `${moduleName}/DEACTIVATE_ITEM_END`;

const SET_QUERY_STRING = `${moduleName}/SET_QUERY_STRING`;

export default {
  SET_COUNT,
  FETCH_ITEM_START,
  FETCH_ITEM_END,

  SET_ITEM,
  FETCH_LIST_START,
  FETCH_LIST_END,

  SET_LIST,
  CREATE_ITEM_START,
  CREATE_ITEM_END,

  UPDATE_ITEM_START,
  UPDATE_ITEM_END,

  DELETE_ITEM_START,
  DELETE_ITEM_END,

  DEACTIVATE_ITEM_START,
  DEACTIVATE_ITEM_END,

  CREATE_ADMIN_START,
  CREATE_ADMIN_END,

  DELETE_ADMIN_START,
  DELETE_ADMIN_END,

  SET_QUERY_STRING,
};
