import { courseTemplates as courseTemplateServices } from '@coligo-org/fe-common/services';
import Types from '../types';
import logger from '../../../../../../../common/services/logService';

import { ErrorsActions } from '../../../../../common/error/state';

export const editCertificateOptionsStart = () => ({
  type: Types.CERTIFICATE_OPTIONS_EDIT_START,
});

export const editCertificateOptionsEnd = () => ({
  type: Types.CERTIFICATE_OPTIONS_EDIT_END,
});

export default ({ courseTemplateId, certificateOptions, callback }) =>
  async dispatch => {
    try {
      dispatch(editCertificateOptionsStart());
      await courseTemplateServices.editCertificateOptions({
        courseTemplateId,
        certificateOptions,
      });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(editCertificateOptionsEnd());
    }
  };
