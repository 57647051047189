import { useEffect, useRef } from 'react';

/**
 *
 * @summary an hook takes element to add event listener to it and it removes the event after the component unmount
 * @param {string} eventName - the event name that will be attached to the handler
 * @param {func} handler - the handler will be attached to that element
 * @param {Node} element - the element you want to attach event listener to it
 */

function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();

  // Update ref.current value if handler changes
  // this is to avoid having the effect executed on every handler reference change
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = event => savedHandler.current(event);
    const isSupported = element && element.addEventListener;
    if (!isSupported) return undefined;

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default useEventListener;
