import {
  applyMiddleware,
  compose,
  combineReducers,
  legacy_createStore as createStore,
} from 'redux';
import { thunk } from 'redux-thunk';
import appTypes from '../reducers/app/types';
import reducers from './reducers';

export default function configureStore(initialState = {}) {
  const appReducer = combineReducers({
    ...reducers,
  });

  const rootReducer = (state, action) => {
    switch (action.type) {
      case 'USER_LOGOUT':
        return appReducer({ i18n: { ...state.i18n } }, action);
      case appTypes.SWITCH_USER_START: {
        return appReducer(
          {
            i18n: { ...state.i18n },
            linkedAccounts: { ...state.linkedAccounts },
            app: { ...state.app },
            auth: { ...state.auth },
          },
          action,
        );
      }
      default:
        return appReducer(state, action);
    }
  };

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line no-underscore-dangle
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-line no-underscore-dangle
      : compose;

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );
}
