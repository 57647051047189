import {
  assessments as AssessmentsServices,
  homeworks as HomeworksServices,
} from '@coligo-org/fe-common/services';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const submitAssessmentStart = () => ({
  type: coursesTypes.SUBMIT_ASSESSMENT_START,
});

export const submitAssessmentEnd = () => ({
  type: coursesTypes.SUBMIT_ASSESSMENT_END,
});

const submitAssessment = (isHomework, assessmentId) =>
  isHomework
    ? HomeworksServices.submitHomework({ homeworkId: assessmentId })
    : AssessmentsServices.submitAssessment({ assessmentId });

export default (
    { assessmentId, callback, errorCallback, isHomework } = {
      isHomework: false,
    },
  ) =>
  async dispatch => {
    try {
      dispatch(submitAssessmentStart());
      await submitAssessment(isHomework, assessmentId);
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        errorCallback(error.response.data);
      }
    } finally {
      dispatch(submitAssessmentEnd());
    }
  };
/* Marking the assessment as not submitted. */
