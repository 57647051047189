import Types from '../types';

const initialState = [];

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.RESET_ERRORS:
      return initialState;
    case Types.ADD_ERROR:
      return reducerState.concat([action.payload]);
    default:
      return reducerState;
  }
};
