const moduleName = 'questions';

const GET_COURSE_TEMPLATES_START = `${moduleName}/GET_COURSE_TEMPLATES_START`;
const GET_COURSE_TEMPLATES_END = `${moduleName}/GET_COURSE_TEMPLATES_END`;

const CREATE_QUESTION_START = `${moduleName}/CREATE_QUESTION_START`;
const CREATE_QUESTION_END = `${moduleName}/CREATE_QUESTION_END`;

const GET_QUESTIONS_START = `${moduleName}/GET_QUESTIONS_START`;
const GET_QUESTIONS_END = `${moduleName}/GET_QUESTIONS_END`;

const UPDATE_QUESTION_START = `${moduleName}/UPDATE_QUESTION_START`;
const UPDATE_QUESTION_END = `${moduleName}/UPDATE_QUESTION_END`;

const DELETE_QUESTION_START = `${moduleName}/DELETE_QUESTION_START`;
const DELETE_QUESTION_END = `${moduleName}/DELETE_QUESTION_END`;

const CHANGE_QUESTION_STATE_START = `${moduleName}/CHANGE_QUESTION_STATE_START`;
const CHANGE_QUESTION_STATE_END = `${moduleName}/CHANGE_QUESTION_STATE_END`;

const GET_QUESTION_START = `${moduleName}/GET_QUESTION_START`;
const GET_QUESTION_END = `${moduleName}/GET_QUESTION_END`;

const GET_SCHOOL_TAGS_START = `${moduleName}/GET_SCHOOL_TAGS_START`;
const GET_SCHOOL_TAGS_END = `${moduleName}/GET_SCHOOL_TAGS_END`;

const CREATE_SCHOOL_TAG_START = `${moduleName}/CREATE_SCHOOL_TAG_START`;
const CREATE_SCHOOL_TAG_END = `${moduleName}/CREATE_SCHOOL_TAG_END`;
const SET_TAGS = `${moduleName}/SET_TAGS`;

export default {
  GET_COURSE_TEMPLATES_START,
  GET_COURSE_TEMPLATES_END,

  CREATE_QUESTION_START,
  CREATE_QUESTION_END,

  GET_QUESTIONS_START,
  GET_QUESTIONS_END,

  GET_QUESTION_START,
  GET_QUESTION_END,

  DELETE_QUESTION_START,
  DELETE_QUESTION_END,

  CHANGE_QUESTION_STATE_START,
  CHANGE_QUESTION_STATE_END,

  UPDATE_QUESTION_START,
  UPDATE_QUESTION_END,

  GET_SCHOOL_TAGS_START,
  GET_SCHOOL_TAGS_END,

  CREATE_SCHOOL_TAG_START,
  CREATE_SCHOOL_TAG_END,

  SET_TAGS,
};
