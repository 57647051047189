import { combineReducers } from 'redux';

import constants from './reducers/constants';
import state from './reducers/state';
import featureFlags from './reducers/featureFlags';

export default combineReducers({
  constants,
  state,
  featureFlags,
});
