import Types from '../types';

const INITIAL_STATE = [];

export default (reducerState = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_MESSAGES:
      return action.shouldResetMessages
        ? action.payload
        : [...reducerState, ...action.payload];
    default:
      return reducerState;
  }
};
