const moduleName = 'gradebook';

const FETCH_SCHOOL_SEMESTERS_START = `${moduleName}/FETCH_SCHOOL_SEMESTERS_START`;
const FETCH_SCHOOL_SEMESTERS_END = `${moduleName}/FETCH_SCHOOL_SEMESTERS_END`;

const COPY_SEMESTER_SETTINGS_START = `${moduleName}/COPY_SEMESTER_SETTINGS_START`;
const COPY_SEMESTER_SETTINGS_END = `${moduleName}/COPY_SEMESTER_SETTINGS_END`;

export default {
  FETCH_SCHOOL_SEMESTERS_START,
  FETCH_SCHOOL_SEMESTERS_END,

  COPY_SEMESTER_SETTINGS_START,
  COPY_SEMESTER_SETTINGS_END,
};
