import { combineReducers } from 'redux';
import data from './reducers/data';
import state from './reducers/state';
import fetchItem from './actions/fetchItem';

export const SchoolActions = {
  fetchItem,
};

export default combineReducers({
  data,
  state,
});
