import { users as usersService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const countNotificationsStart = () => ({
  type: Types.COUNT_NOTIFICATIONS_START,
});

export const countNotificationsEnd = () => ({
  type: Types.COUNT_NOTIFICATIONS_END,
});

export default ({ callback }) =>
  async dispatch => {
    try {
      dispatch(countNotificationsStart());
      const { data } = await usersService.getUnseenNotificationsCount();
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(countNotificationsEnd());
    }
  };
