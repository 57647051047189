import { grayTitle } from '../../../../../../common/assets/jss/appStyle';

export default () => ({
  paper: {
    margin: '1rem',
    paddingBlock: '20px',
  },
  container: {
    alignItems: 'center',
  },
  message: {
    paddingInline: '1rem',
    paddingBlock: '1rem',
    color: grayTitle,
    textAlign: 'center',
  },
  hyperLink: {
    color: 'royalblue',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    fontSize: '14px',
    textAlign: 'center',
  },

  countDownTimer: {
    color: 'royalblue',
    fontSize: '14px',
  },
  image: {
    width: '10%',
  },
  emailVerificationImg: {
    width: '15%',
  },
  subHeaderText: {
    fontSize: '14px',
  },
  headerText: {
    fontSize: '21px',
  },
  textField: {
    width: '40%',
  },
  verifyButton: {
    margin: '10px',
  },
});
