import { schools as schoolServices } from '@coligo-org/fe-common/services';
import Types from '../types';
import logger from '../../../../../../../common/services/logService';

export const fetchItemStart = () => ({
  type: Types.FETCH_ITEM_START,
});

export const fetchItemEnd = () => ({
  type: Types.FETCH_ITEM_END,
});

export const setItem = item => ({
  type: Types.SET_ITEM,
  payload: item,
});

export default schoolId => async dispatch => {
  try {
    dispatch(fetchItemStart());
    const response = await schoolServices.getSchool(schoolId);
    dispatch(setItem(response.data.school));
  } catch (error) {
    logger.log(error);
  } finally {
    dispatch(fetchItemEnd());
  }
};
