import Types from '../types';

const initialState = {
  isListingNotifications: false,
  isCountingNotifications: false,
  isUpdatingNotification: false,
  isUpdatingNotifications: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.LIST_NOTIFICATIONS_START:
      return {
        ...reducerState,
        isListingNotifications: true,
      };

    case Types.UPDATE_NOTIFICATION_START:
      return {
        ...reducerState,
        isUpdatingNotification: true,
      };

    case Types.UPDATE_NOTIFICATIONS_START:
      return {
        ...reducerState,
        isUpdatingNotifications: true,
      };

    case Types.LIST_NOTIFICATIONS_END:
      return {
        ...reducerState,
        isListingNotifications: false,
      };

    case Types.UPDATE_NOTIFICATION_END:
      return {
        ...reducerState,
        isUpdatingNotification: false,
      };

    case Types.UPDATE_NOTIFICATIONS_END:
      return {
        ...reducerState,
        isUpdatingNotifications: false,
      };

    case Types.COUNT_NOTIFICATIONS_START:
      return {
        ...reducerState,
        isCountingNotifications: true,
      };

    case Types.COUNT_NOTIFICATIONS_END:
      return {
        ...reducerState,
        isCountingNotifications: false,
      };

    default:
      return reducerState;
  }
};
