import { useSelector } from 'react-redux';
import constants from '@coligo-org/fe-common/constants';

/**
 * @summary A hook that returns the role of the current user or an array that contains all the roles in the system
 * @returns {boolean} isTeacher
 * @returns {boolean} isParent
 * @returns {boolean} isStudent
 * @returns {boolean} isPrincipal
 * @returns {boolean} isHOD
 * @returns {boolean} isSchoolAdmin
 * @returns {boolean} isOrganizationAdmin
 * @returns {boolean} isAdmin
 * @returns {boolean} isStaff
 * @returns {object}  allRoles : {
                      SUPER_ADMIN: string,
                      ORGANIZATION_ADMIN: string,
                      SCHOOL_ADMIN: string,
                      HOD: string,
                      PRINCIPAL: string,
                      PARENT": string,
                      TEACHER": string,
                      STUDENT: string
                    },
  @returns {string} userBaseRole
 */

const useRoles = () => {
  const user = useSelector(state => state.auth.user);
  const userBaseRole = user?.baseRole?.name;

  const isAdmin =
    user?.baseRole?.name === constants.BASE_ROLES.SCHOOL_ADMIN ||
    user?.baseRole?.name === constants.BASE_ROLES.SUPER_ADMIN ||
    user?.baseRole?.name === constants.BASE_ROLES.ORGANIZATION_ADMIN;
  return {
    isTeacher: user?.baseRole?.name === constants.BASE_ROLES.TEACHER,
    isParent: user?.baseRole?.name === constants.BASE_ROLES.PARENT,
    isStudent: user?.baseRole?.name === constants.BASE_ROLES.STUDENT,
    isPrincipal: user?.baseRole?.name === constants.BASE_ROLES.PRINCIPAL,
    isHOD: user?.baseRole?.name === constants.BASE_ROLES.HOD,
    isSchoolAdmin: user?.baseRole?.name === constants.BASE_ROLES.SCHOOL_ADMIN,
    isSuperAdmin: user?.baseRole?.name === constants.BASE_ROLES.SUPER_ADMIN,
    isOrganizationAdmin:
      user?.baseRole?.name === constants.BASE_ROLES.ORGANIZATION_ADMIN,
    isAdmin,
    isStaff: [
      constants.BASE_ROLES.TEACHER,
      constants.BASE_ROLES.PRINCIPAL,
      constants.BASE_ROLES.HOD,
      constants.BASE_ROLES.SUPER_ADMIN,
      constants.BASE_ROLES.ORGANIZATION_ADMIN,
      constants.BASE_ROLES.SCHOOL_ADMIN,
    ].includes(user?.baseRole?.name),
    allRoles: constants.BASE_ROLES,
    userBaseRole,
  };
};
export default useRoles;
