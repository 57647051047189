import { schools as schoolsServices } from '@coligo-org/fe-common/services';
import Types from '../types';
import logger from '../../../../../../../common/services/logService';
import { ErrorsActions } from '../../../../../common/error/state';

import handleImportErrors from '../../../../helpers/handleImportErrors';

export const importItemStart = () => ({
  type: Types.IMPORT_ITEMS_START,
});

export const importItemEnd = () => ({
  type: Types.IMPORT_ITEMS_END,
});

export default ({ schoolId, courseTemplatesCSV, onUploadProgress, callback }) =>
  async dispatch => {
    try {
      dispatch(importItemStart());
      const { data } = await schoolsServices.importCourseTemplates({
        schoolId,
        data: courseTemplatesCSV,
        onUploadProgress,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response && error.response.data.meta) {
        handleImportErrors({ meta: error.response.data.meta }).forEach(err =>
          dispatch(ErrorsActions.addError(err)),
        );
      }
    } finally {
      dispatch(importItemEnd());
    }
  };
