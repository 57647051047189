import Types from '../types';

const initialState = {
  isSidebarMinimized: false,
  isSidebarHidden: true,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.MINIMIZE_SIDEBAR:
      return {
        ...reducerState,
        isSidebarMinimized: action.payload,
      };

    case Types.HIDE_SIDEBAR:
      return {
        ...reducerState,
        isSidebarHidden: action.payload,
      };

    case Types.TOGGLE_SIDEBAR_MINIMIZE:
      return {
        ...reducerState,
        isSidebarMinimized: !reducerState.isSidebarMinimized,
      };

    case Types.TOGGLE_SIDEBAR_HIDE:
      return {
        ...reducerState,
        isSidebarHidden: !reducerState.isSidebarHidden,
      };
    default:
      return reducerState;
  }
};
