import { gradeCategories as gradeCategoriesService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../../../../common/error/state';
import fetchList from './fetchList';
import logger from '../../../../../../../../../../common/services/logService';
import Types from '../types';

export const deleteItemStart = () => ({
  type: Types.DELETE_ITEM_START,
});

export const deleteItemEnd = () => ({
  type: Types.DELETE_ITEM_END,
});

export default ({ schoolId, itemId, callback }) =>
  async dispatch => {
    try {
      dispatch(deleteItemStart());
      await gradeCategoriesService.deleteGradeCategory(itemId);
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(deleteItemEnd());
      dispatch(fetchList(schoolId));
    }
  };
