import loginBackground from '../../../common/assets/img/login-background.jpg';

import { dangerColor, successColor } from '../../../common/assets/jss/appStyle';

export default theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${loginBackground})`,
    backgroundPosition: 'center',
    [theme.breakpoints.up(400)]: {
      backgroundSize: 'cover',
    },
  },
  paper: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    '@media (max-width: 900px)': {
      width: '80%',
    },
  },
  successIcon: {
    fontSize: '5rem',
    color: successColor,
  },
  errorIcon: {
    fontSize: '5rem',
    color: dangerColor,
  },
  success: {
    color: successColor,
    marginBlockEnd: '1rem',
  },
  failure: {
    color: dangerColor,
    marginBlockEnd: '1rem',
  },
  hyperLink: {
    color: 'royalblue',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    fontSize: '1rem',
    textAlign: 'center',
    margin: 'inherit',
  },
  title: {
    marginBlock: '1rem',
  },
  input: {
    marginBlockStart: '1rem',
    fontSize: '1rem',
    width: '75%',
  },
  button: {
    background: 'rgb(63, 81, 181, 1)',
    color: 'white',
    marginBlockStart: '1rem',
    '&:hover': {
      cursor: 'pointer',
      background: 'rgb(63, 81, 181, 0.8)',
    },
  },
  caption: {
    marginBlockStart: '1rem',
    fontSize: '1rem',
  },
  message: {
    textAlign: 'center',
  },
  requestPassSentImg: {
    width: '15%',
  },
  subHeaderText: {
    fontSize: '14px',
  },
  headerText: {
    fontSize: '1.2rem',
    marginBlock: '1rem',
    textAlign: 'center',
  },
  userEmail: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '6px',
    fontSize: '1.2rem',
  },
  error: {
    color: 'red',
    alignSelf: 'flex-start',
    marginInlineStart: '10%',
  },
  InfoIcon: {
    verticalAlign: 'bottom',
    marginInlineStart: '.5rem',
  },
  notVerifiedEmail: {
    color: 'red',
    marginBlockStart: '1rem',
  },
  hidden: {
    visibility: 'hidden',
  },
  usersImage: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    border: `1px solid `,
  },
});
