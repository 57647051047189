import { combineReducers } from 'redux';

import list from './reducers/list';
import item from './reducers/item';
import state from './reducers/state';
import courses from './reducers/courses';

export default combineReducers({
  list,
  state,
  item,
  courses,
});
