export const DEFAULT_LOCALE = 'en';
export const SUPPORTED_LOCALES = ['en', 'ar'];

export const getDefaultLanguage = () => {
  const currentLanguage = localStorage.getItem('locale');

  return SUPPORTED_LOCALES.includes(currentLanguage)
    ? currentLanguage
    : DEFAULT_LOCALE;
};
