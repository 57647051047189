import { semesters as semesterServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import { SchoolActions } from '../../../school/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const copySemesterCoursesStart = () => ({
  type: Types.COPY_SEMESTER_COURSES_START,
});

export const copySemesterCoursesEnd = () => ({
  type: Types.COPY_SEMESTER_COURSES_END,
});

export default ({ schoolId, semesterId, data, callback }) =>
  async dispatch => {
    try {
      dispatch(copySemesterCoursesStart());
      await semesterServices.copySemesterCourses({ semesterId, data });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(copySemesterCoursesEnd());
      dispatch(SchoolActions.fetchItem(schoolId));
    }
  };
