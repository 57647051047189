import { coursesTypes } from '../../reducers/types';

export const setCourse = course => ({
  type: coursesTypes.CHANGE_AUTOSAVE_STATE,
  payload: course,
});

export default state => dispatch => {
  dispatch(setCourse(state));
};
