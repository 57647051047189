import { subjects as subjectsServices } from '@coligo-org/fe-common/services';
import { SchoolActions } from '../../../school/state';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

const deleteItemStart = () => ({
  type: Types.DELETE_ITEM_START,
});

const deleteItemEnd = () => ({
  type: Types.DELETE_ITEM_END,
});

export default ({ schoolId, itemId, callback }) =>
  async dispatch => {
    dispatch(deleteItemStart());
    try {
      await subjectsServices.deleteSubject(itemId);
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      dispatch(ErrorsActions.addError(error.response.data.message));
    } finally {
      dispatch(deleteItemEnd());
      dispatch(SchoolActions.fetchItem(schoolId));
    }
  };
