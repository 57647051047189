import { academicYear as academicYearServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const fetchCoursesStart = () => ({
  type: Types.FETCH_COURSES_START,
});

export const fetchCoursesEnd = () => ({
  type: Types.FETCH_COURSES_END,
});

export const setCourses = courses => ({
  type: Types.SET_COURSES,
  payload: courses,
});

export default ({ academicYearId, params }) =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      dispatch(fetchCoursesStart());
      const { data, totalCount } =
        await academicYearServices.getAcademicYearCourses({
          academicYearId,
          params,
        });
      dispatch(setCourses(academicYearId ? data.data : data));
      return { data, totalCount };
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(fetchCoursesEnd());
    }
  };
