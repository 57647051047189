const moduleName = 'studentGradebook';

const GET_GRADEBOOK_CERTIFICATE_START = `${moduleName}/GET_GRADEBOOK_CERTIFICATE_START`;
const GET_GRADEBOOK_CERTIFICATE_END = `${moduleName}/GET_GRADEBOOK_CERTIFICATE_END`;

const GET_GRADEBOOK_SUMMARY_START = `${moduleName}/GET_GRADEBOOK_SUMMARY_START`;
const GET_GRADEBOOK_SUMMARY_END = `${moduleName}/GET_GRADEBOOK_SUMMARY_END`;

const GET_STUDENT_SEMESTERS_START = `${moduleName}/GET_STUDENT_SEMESTERS_START`;
const GET_STUDENT_SEMESTERS_END = `${moduleName}/GET_STUDENT_SEMESTERS_END`;

export default {
  GET_GRADEBOOK_CERTIFICATE_START,
  GET_GRADEBOOK_CERTIFICATE_END,

  GET_GRADEBOOK_SUMMARY_START,
  GET_GRADEBOOK_SUMMARY_END,

  GET_STUDENT_SEMESTERS_START,
  GET_STUDENT_SEMESTERS_END,
};
