import { schools as schoolsServices } from '@coligo-org/fe-common/services';
import { SchoolActions } from '../../../school/state';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

const createItemStart = () => ({
  type: Types.CREATE_ITEM_START,
});

const createItemEnd = () => ({
  type: Types.CREATE_ITEM_END,
});

export default (schoolId, semesters, callback) => async dispatch => {
  dispatch(createItemStart());
  try {
    await schoolsServices.createAcademicYear(schoolId, semesters);
    callback();
  } catch (error) {
    logger.log(error);
    dispatch(ErrorsActions.addError(error.response.data.message));
  } finally {
    dispatch(SchoolActions.fetchItem(schoolId));
    dispatch(createItemEnd());
  }
};
