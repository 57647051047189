const moduleName = 'notifications';

const LIST_NOTIFICATIONS_START = `${moduleName}/LIST_NOTIFICATIONS_START`;
const LIST_NOTIFICATIONS_END = `${moduleName}/LIST_NOTIFICATIONS_END`;

const COUNT_NOTIFICATIONS_START = `${moduleName}/COUNT_NOTIFICATIONS_START`;
const COUNT_NOTIFICATIONS_END = `${moduleName}/COUNT_NOTIFICATIONS_END`;

const UPDATE_NOTIFICATION_START = `${moduleName}/UPDATE_NOTIFICATION_START`;
const UPDATE_NOTIFICATION_END = `${moduleName}/UPDATE_NOTIFICATION_END`;

const UPDATE_NOTIFICATIONS_START = `${moduleName}/UPDATE_NOTIFICATIONS_START`;
const UPDATE_NOTIFICATIONS_END = `${moduleName}/UPDATE_NOTIFICATIONS_END`;

const SET_UNREAD_COUNT_CALENDAR = `${moduleName}/SET_UNREAD_COUNT_CALENDAR`;
const SET_UNREAD_COUNT_SCHEDULE = `${moduleName}/SET_UNREAD_COUNT_SCHEDULE`;

export default {
  LIST_NOTIFICATIONS_START,
  LIST_NOTIFICATIONS_END,

  COUNT_NOTIFICATIONS_START,
  COUNT_NOTIFICATIONS_END,

  UPDATE_NOTIFICATION_START,
  UPDATE_NOTIFICATION_END,

  UPDATE_NOTIFICATIONS_START,
  UPDATE_NOTIFICATIONS_END,

  SET_UNREAD_COUNT_CALENDAR,
  SET_UNREAD_COUNT_SCHEDULE,
};
