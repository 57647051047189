export default ({ meta }) => {
  const errors = [];

  if (meta.parsingErrors) {
    meta.parsingErrors.forEach(error =>
      errors.push(`${error.message} in row ${error.row}.`),
    );
  }

  if (meta.duplicatesRowsMap) {
    Object.entries(meta.duplicatesRowsMap).forEach(error =>
      errors.push(
        `Duplicated National ID ${error[0]} on rows: ${error[1].join(', ')}.`,
      ),
    );
  }

  if (meta.validationErrors) {
    meta.validationErrors.forEach(error =>
      errors.push(`Invalid Schema in row ${error.row + 2}.`),
    );
  }

  return errors;
};
