import { combineReducers } from 'redux';

import createItem from './actions/createItem';
import importCourseTemplates from './actions/importCourseTemplates';
import exportCourseTemplates from './actions/exportCourseTemplates';
import deleteItem from './actions/deleteItem';
import fetchList from './actions/fetchList';
import editCertificateOptions from './actions/editCertificateOptions';

import list from './reducers/list';
import state from './reducers/state';

export const CourseTemplatesActions = {
  fetchList,
  createItem,
  importCourseTemplates,
  exportCourseTemplates,
  editCertificateOptions,
  deleteItem,
};

export default combineReducers({
  list,
  state,
});
