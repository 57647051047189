export default () => ({
  loading: {
    position: 'absolute',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '8px',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  disableEvents: {
    pointerEvents: 'none',
  },
});
