import { combineReducers } from 'redux';

import fetchList from './actions/fetchList';
import createItem from './actions/createItem';
import updateItem from './actions/updateItem';
import deleteItem from './actions/deleteItem';
import list from './reducers/list';
import state from './reducers/state';

export const CourseGradebookWeightsActions = {
  fetchList,
  createItem,
  updateItem,
  deleteItem,
};

export default combineReducers({
  list,
  state,
});
