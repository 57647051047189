import { messages as messagesService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const setUnreadCount = count => ({
  type: Types.SET_UNREAD_COUNT,
  payload: count,
});

export default ({ callback }) =>
  async dispatch => {
    try {
      const { data } = await messagesService.getUnreadCount();
      dispatch(setUnreadCount(data.count));
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    }
  };
