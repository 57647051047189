import Types from '../types';

const initialState = {
  isUpdatingItem: false,
  isCopyingCourses: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_ITEM_START:
      return {
        ...reducerState,
        isUpdatingItem: true,
      };
    case Types.UPDATE_ITEM_END:
      return {
        ...reducerState,
        isUpdatingItem: false,
      };
    case Types.COPY_SEMESTER_COURSES_START:
      return {
        ...reducerState,
        isCopyingCourses: true,
      };
    case Types.COPY_SEMESTER_COURSES_END:
      return {
        ...reducerState,
        isCopyingCourses: false,
      };
    default:
      return reducerState;
  }
};
