import {
  auth as authService,
  users as usersServices,
} from '@coligo-org/fe-common/services';
import { updateUserStart, updateUserEnd } from './updateUser';

import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { refreshUserData } from '../../../auth/state/actions/auth';

export default ({ user, userName, callback }) =>
  async dispatch => {
    try {
      dispatch(updateUserStart());
      await usersServices.updateMe(user);
      await authService.refreshToken({ userName });
      dispatch(refreshUserData());
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateUserEnd());
    }
  };
