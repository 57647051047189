import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { users as userServices } from '@coligo-org/fe-common/services';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { useNavigate } from 'react-router-dom';
import LoadableButton from '../../../common/components/LoadableButton';

import styles from './styles';

const useStyles = makeStyles(styles);

function ResetNewPassword({
  setIsSuccessReset,
  setIsFailingReset,
  setShowReset,
}) {
  const [showPassword, setshowPassword] = useState(false);
  const [Password, setPassword] = useState({
    NewPassword: '',
    ConfirmPassword: '',
  });
  const [Errors, setErrors] = useState({
    NewPassword: false,
    ConfirmPassword: false,
    doNotMatch: false,
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const username = new URLSearchParams(window.location.search).get('username');
  const token = new URLSearchParams(window.location.search).get('token');

  const classes = useStyles();

  const validate = () => {
    setSubmitted(true);
    if (Password.NewPassword !== Password.ConfirmPassword) {
      setErrors({ ...Errors, doNotMatch: true });
      return false;
    }
    if (Errors.NewPassword || Errors.ConfirmPassword) return false;
    if (Password.NewPassword.length === 0) {
      setErrors({
        ...Errors,
        NewPassword: true,
        ConfirmPassword: true,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validate()) return;
    setLoading(true);
    try {
      await userServices.resetPassword({
        username,
        newPassword: Password.NewPassword,
        token,
      });
      setShowReset(false);
      setIsSuccessReset(true);
    } catch (error) {
      setShowReset(false);
      setIsFailingReset(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Reset Password
      </Typography>

      <Typography variant="body2">
        Please enter the new password for {username}
      </Typography>
      <TextField
        variant="standard"
        className={classes.input}
        label="New Password"
        type={showPassword ? 'text' : 'password'}
        value={Password.NewPassword}
        error={submitted && Errors.NewPassword}
        helperText={
          submitted &&
          Errors.NewPassword &&
          'Password must be at least 8 characters long'
        }
        onChange={e => {
          setPassword({ ...Password, NewPassword: e.target.value });
          setErrors({
            ...Errors,
            NewPassword: e.target.value.length <= 7,
            doNotMatch: false,
          });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                onClick={() => setshowPassword(!showPassword)}
                size="large"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="standard"
        className={classes.input}
        label="Confirm New Password"
        type={showPassword ? 'text' : 'password'}
        value={Password.ConfirmPassword}
        error={submitted && Errors.ConfirmPassword}
        helperText={
          submitted &&
          Errors.ConfirmPassword &&
          'Password must be at least 8 characters long'
        }
        onChange={e => {
          setPassword({ ...Password, ConfirmPassword: e.target.value });
          setErrors({
            ...Errors,
            ConfirmPassword: e.target.value.length <= 7,
            doNotMatch: false,
          });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton
                onClick={() => setshowPassword(!showPassword)}
                size="large"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadableButton
        type="submit"
        variant="contained"
        isLoading={loading}
        fullWidth
        color="primary"
        LoadingIndicatorStyle={{ position: 'relative' }}
        disabled={false}
        className={classes.button}
        onClick={handleSubmit}
      >
        Reset Password
      </LoadableButton>
      {submitted && Errors.doNotMatch && (
        <FormHelperText error>
          Inputs do not match.Please check your inputs.
        </FormHelperText>
      )}
    </>
  );
}

ResetNewPassword.propTypes = {
  setIsSuccessReset: PropTypes.func.isRequired,
  setIsFailingReset: PropTypes.func.isRequired,
  setShowReset: PropTypes.func.isRequired,
};

function SuccessReset() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <CheckIcon className={classes.successIcon} />
      <Typography variant="h1" className={classes.success}>
        Success
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        display="inline"
        className={classes.message}
      >
        Your password was reset successfully <br /> now you can{' '}
        <Typography
          component="span"
          display="inline"
          className={classes.hyperLink}
          onClick={() => {
            navigate('', { replace: true });
          }}
        >
          login
        </Typography>{' '}
        to your account
      </Typography>
    </>
  );
}

function FailingReset() {
  const classes = useStyles();
  return (
    <>
      <ErrorIcon className={classes.errorIcon} />
      <Typography variant="h1" className={classes.failure}>
        Error
      </Typography>
      <Typography variant="subtitle1" className={classes.message}>
        Something went wrong, Please check the following:
        <br />
        1. Your account is Activated.
        <br />
        2. You are not logged in to another account in another tab.
        <br />
        3. Try again later
      </Typography>
    </>
  );
}

function Reset() {
  const [isSuccessReset, setIsSuccessReset] = useState(false);
  const [isFailingReset, setIsFailingReset] = useState(false);
  const [showReset, setShowReset] = useState(true);

  const classes = useStyles();

  return (
    <main className={classes.main}>
      <Paper className={classes.paper} elevation={6}>
        {showReset && (
          <ResetNewPassword
            setIsSuccessReset={setIsSuccessReset}
            setIsFailingReset={setIsFailingReset}
            setShowReset={setShowReset}
          />
        )}
        {isFailingReset && <FailingReset />}
        {isSuccessReset && <SuccessReset />}
      </Paper>
    </main>
  );
}

export default Reset;
