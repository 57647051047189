import { schools as schoolsServices } from '@coligo-org/fe-common/services';
import { SchoolActions } from '../../../school/state';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

const addItemStart = () => ({
  type: Types.ADD_ITEM_START,
});

const addItemEnd = () => ({
  type: Types.ADD_ITEM_END,
});

export default ({ schoolId, name, callback }) =>
  async dispatch => {
    dispatch(addItemStart());
    try {
      await schoolsServices.createSubject(schoolId, name);
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      dispatch(ErrorsActions.addError(error.response.data.message));
    } finally {
      dispatch(addItemEnd());
      dispatch(SchoolActions.fetchItem(schoolId));
    }
  };
