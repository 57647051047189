import { academicYear as academicYearServices } from '@coligo-org/fe-common/services';
import Types from '../types';
import logger from '../../../../../../../common/services/logService';
import { ErrorsActions } from '../../../../../common/error/state';
import downloadFile from '../../../../../../../common/helpers/downloadFile';

export const exportItemsStart = () => ({
  type: Types.EXPORT_ITEMS_START,
});

export const exportItemsEnd = () => ({
  type: Types.EXPORT_ITEMS_END,
});

export default ({ academicYearId, callback }) =>
  async dispatch => {
    try {
      dispatch(exportItemsStart());
      const { data } = await academicYearServices.exportCourses({
        academicYearId,
      });
      downloadFile('courses.csv', data.csv, 'text/csv');
      if (callback) callback(data.csv);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(exportItemsEnd());
    }
  };
