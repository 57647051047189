import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { tooltip } from './jss/appStyle';

const generateSafariSpacingOverrides = () => {
  const spacings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const newGridSpacing = {};
  spacings.forEach(spacing => {
    newGridSpacing[`spacing-xs-${spacing}`] = {
      width: `calc(100% + ${8 * spacing + 1}px)`,
    };
  });
  return newGridSpacing;
};

export default createTheme(
  adaptV4Theme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#071413',
            textTransform: 'none',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip,
        },
      },
      MuiGrid: {
        styleOverrides: {
          ...generateSafariSpacingOverrides(),
        },
      },
    },

    typography: {
      fontFamily: 'Tajawal',
      fontFamily2: 'Arbutus Slab',
      h1: {
        fontSize: '32px',
        fontWeight: '500',
      },
      h2: {
        fontSize: '28px',
        fontWeight: '700',
      },
      h3: {
        fontSize: '24px',
        fontWeight: '700',
      },
      h4: {
        fontSize: '20px',
        fontWeight: '700',
      },
      h5: {
        fontSize: '20px',
        fontWeight: '500',
      },
      h6: {
        fontSize: '18px',
        fontWeight: '500',
        textTransform: 'none',
      },
      subtitle1: {
        fontSize: '16px',
        fontWeight: '500',
        textTransform: 'none',
      },
      subtitle2: {
        fontSize: '40px',
        fontWeight: '700',
      },
      body1: {
        fontSize: '18px',
        fontWeight: '400',
      },
      body2: {
        fontSize: '12px',
        fontWeight: '500',
      },
      caption: {
        fontSize: '12px',
      },
      button: {
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '18px',
      },
    },
  }),
);
