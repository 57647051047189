const moduleName = 'Courses';

const GET_SEMESTERS_START = `${moduleName}/GET_SEMESTERS_START`;
const GET_SEMESTERS_END = `${moduleName}/GET_SEMESTERS_END`;

const GET_COURSE_START = `${moduleName}/GET_COURSE_START`;
const GET_COURSE_END = `${moduleName}/GET_COURSE_END`;
const SET_COURSE = `${moduleName}/SET_COURSE`;

const GET_COURSE_MATERIALS_START = `${moduleName}/GET_COURSE_MATERIALS_START`;
const GET_COURSE_MATERIALS_END = `${moduleName}/GET_COURSE_MATERIALS_END`;
const SET_COURSE_MATERIALS = `${moduleName}/SET_COURSE_MATERIALS`;

const GET_GRADABLE_ITEMS_START = `${moduleName}/GET_GRADABLE_ITEMS_START`;
const GET_GRADABLE_ITEMS_END = `${moduleName}/GET_GRADABLE_ITEMS_END`;
const SET_GRADABLE_ITEMS = `${moduleName}/SET_GRADABLE_ITEMS`;

const SHALLOW_GET_COURSE_MATERIALS_START = `${moduleName}/SHALLOW_GET_COURSE_MATERIALS_START`;
const SHALLOW_GET_COURSE_MATERIALS_END = `${moduleName}/SHALLOW_GET_COURSE_MATERIALS_END`;

const DELETE_MATERIAL_START = `${moduleName}/DELETE_MATERIAL_START`;
const DELETE_MATERIAL_END = `${moduleName}/DELETE_MATERIAL_END`;

const CREATE_MATERIAL_START = `${moduleName}/CREATE_MATERIAL_START`;
const CREATE_MATERIAL_END = `${moduleName}/CREATE_MATERIAL_END`;

const GET_MATERIAL_START = `${moduleName}/GET_MATERIAL_START`;
const GET_MATERIAL_END = `${moduleName}/GET_MATERIAL_END`;

const UPDATE_MATERIAL_START = `${moduleName}/UPDATE_MATERIAL_START`;
const UPDATE_MATERIAL_END = `${moduleName}/UPDATE_MATERIAL_END`;

const CREATE_GRADABLE_ITEM_START = `${moduleName}/CREATE_GRADABLE_ITEM_START`;
const CREATE_GRADABLE_ITEM_END = `${moduleName}/CREATE_GRADABLE_ITEM_END`;

const GET_GRADABLE_ITEM_START = `${moduleName}/GET_GRADABLE_ITEM_START`;
const GET_GRADABLE_ITEM_END = `${moduleName}/GET_GRADABLE_ITEM_END`;

const GET_GRADEBOOK_POLICY_START = `${moduleName}/GET_GRADEBOOK_POLICY_START`;
const GET_GRADEBOOK_POLICY_END = `${moduleName}/GET_GRADEBOOK_POLICY_END`;
const SET_GRADEBOOK_POLICY = `${moduleName}/SET_GRADEBOOK_POLICY`;

const UPDATE_GRADABLE_ITEM_START = `${moduleName}/UPDATE_GRADABLE_ITEM_START`;
const UPDATE_GRADABLE_ITEM_END = `${moduleName}/UPDATE_GRADABLE_ITEM_END`;

const CREATE_ASSESSMENT_START = `${moduleName}/CREATE_ASSESSMENT_START`;
const CREATE_ASSESSMENT_END = `${moduleName}/CREATE_ASSESSMENT_END`;

const GET_ASSESSMENTS_START = `${moduleName}/GET_ASSESSMENTS_START`;
const GET_ASSESSMENTS_END = `${moduleName}/GET_ASSESSMENTS_END`;
const SET_COURSE_ASSESSMENTS = `${moduleName}/SET_COURSE_ASSESSMENTS`;
const SET_COURSE_HOMEWORKS = `${moduleName}/SET_COURSE_HOMEWORKS`;

const GET_CLASSWORK_START = `${moduleName}/GET_CLASSWORK_START`;
const GET_CLASSWORK_END = `${moduleName}/GET_CLASSWORK_END`;
const SET_COURSE_CLASSWORK = `${moduleName}/SET_COURSE_CLASSWORK`;

const SUBMIT_ASSESSMENT_START = `${moduleName}/SUBMIT_ASSESSMENT_START`;
const SUBMIT_ASSESSMENT_END = `${moduleName}/SUBMIT_ASSESSMENT_END`;

const UNSUBMIT_ASSESSMENT_START = `${moduleName}/UNSUBMIT_ASSESSMENT_START`;
const UNSUBMIT_ASSESSMENT_END = `${moduleName}/UNSUBMIT_ASSESSMENT_END`;

const DELETE_ASSESSMENT_START = `${moduleName}/DELETE_ASSESSMENT_START`;
const DELETE_ASSESSMENT_END = `${moduleName}/DELETE_ASSESSMENT_END`;

const CANCEL_ASSESSMENT_START = `${moduleName}/CANCEL_ASSESSMENT_START`;
const CANCEL_ASSESSMENT_END = `${moduleName}/CANCEL_ASSESSMENT_END`;

const GET_ASSESSMENT_START = `${moduleName}/GET_ASSESSMENT_START`;
const GET_ASSESSMENT_END = `${moduleName}/GET_ASSESSMENT_END`;

const COPY_ASSESSMENT_START = `${moduleName}/COPY_ASSESSMENT_START`;
const COPY_ASSESSMENT_END = `${moduleName}/COPY_ASSESSMENT_END`;

const RESTORE_ASSESSMENT_START = `${moduleName}/RESTORE/ASSESSMENT_START`;
const RESTORE_ASSESSMENT_END = `${moduleName}/RESTORE/ASSESSMENT_END`;

const CHANGE_AUTOSAVE_STATE = `${moduleName}/CHANGE_AUTOSAVE_STATE`;

const GET_ASSESSMENT_SUBMISSIONS_START = `${moduleName}/GET_ASSESSMENT_SUBMISSIONS_START`;
const GET_ASSESSMENT_SUBMISSIONS_END = `${moduleName}/GET_ASSESSMENT_SUBMISSIONS_END`;

const SUBMIT_SUBMISSION_START = `${moduleName}/SUBMIT_SUBMISSION_START`;
const SUBMIT_SUBMISSION_END = `${moduleName}/SUBMIT_SUBMISSION_END`;

const COPY_GRADABLE_ITEM_START = `${moduleName}/COPY_GRADABLE_ITEM_START`;
const COPY_GRADABLE_ITEM_END = `${moduleName}/COPY_GRADABLE_ITEM_END`;

const GET_FOLDER_CONTENT_START = `${moduleName}/GET_FOLDER_CONTENT_START`;
const GET_FOLDER_CONTENT_END = `${moduleName}/GET_FOLDER_CONTENT_END`;

const SET_HIDDEN_GRADES = `${moduleName}/SET_HIDDEN_GRADES`;
export default {
  GET_SEMESTERS_START,
  GET_SEMESTERS_END,

  GET_COURSE_START,
  GET_COURSE_END,
  SET_COURSE,

  GET_COURSE_MATERIALS_START,
  GET_COURSE_MATERIALS_END,
  SET_COURSE_MATERIALS,

  GET_GRADABLE_ITEMS_START,
  GET_GRADABLE_ITEMS_END,
  SET_GRADABLE_ITEMS,

  SHALLOW_GET_COURSE_MATERIALS_START,
  SHALLOW_GET_COURSE_MATERIALS_END,

  DELETE_MATERIAL_START,
  DELETE_MATERIAL_END,

  CREATE_MATERIAL_START,
  CREATE_MATERIAL_END,

  GET_MATERIAL_START,
  GET_MATERIAL_END,

  UPDATE_MATERIAL_START,
  UPDATE_MATERIAL_END,

  CREATE_GRADABLE_ITEM_START,
  CREATE_GRADABLE_ITEM_END,

  GET_GRADABLE_ITEM_START,
  GET_GRADABLE_ITEM_END,

  GET_GRADEBOOK_POLICY_START,
  GET_GRADEBOOK_POLICY_END,
  SET_GRADEBOOK_POLICY,

  UPDATE_GRADABLE_ITEM_START,
  UPDATE_GRADABLE_ITEM_END,

  CREATE_ASSESSMENT_START,
  CREATE_ASSESSMENT_END,

  GET_ASSESSMENTS_START,
  GET_ASSESSMENTS_END,

  SET_COURSE_ASSESSMENTS,
  SET_COURSE_HOMEWORKS,
  GET_CLASSWORK_START,
  GET_CLASSWORK_END,
  SET_COURSE_CLASSWORK,

  SUBMIT_ASSESSMENT_START,
  SUBMIT_ASSESSMENT_END,

  UNSUBMIT_ASSESSMENT_START,
  UNSUBMIT_ASSESSMENT_END,

  DELETE_ASSESSMENT_START,
  DELETE_ASSESSMENT_END,

  CANCEL_ASSESSMENT_START,
  CANCEL_ASSESSMENT_END,

  GET_ASSESSMENT_START,
  GET_ASSESSMENT_END,

  COPY_ASSESSMENT_START,
  COPY_ASSESSMENT_END,

  RESTORE_ASSESSMENT_START,
  RESTORE_ASSESSMENT_END,

  CHANGE_AUTOSAVE_STATE,

  GET_ASSESSMENT_SUBMISSIONS_START,
  GET_ASSESSMENT_SUBMISSIONS_END,

  SUBMIT_SUBMISSION_START,
  SUBMIT_SUBMISSION_END,

  COPY_GRADABLE_ITEM_START,
  COPY_GRADABLE_ITEM_END,

  GET_FOLDER_CONTENT_START,
  GET_FOLDER_CONTENT_END,

  SET_HIDDEN_GRADES,
};
