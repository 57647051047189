import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classnames from 'classnames';

import styles from './styles';

const loadable = Component => {
  function LoadableComponent({
    children,
    isLoading,
    classes,
    transparent,
    isComponent,
    sendIsLoading,
    withOverlay,
    LoadingIndicatorStyle,
    ...props
  }) {
    const shouldChildrenRender = (!isLoading || transparent) && !isComponent;
    const renderedChildren = shouldChildrenRender && children;

    const loader = (
      <CircularProgress
        color="secondary"
        size={26}
        className={`${classes.loading} ${LoadingIndicatorStyle}`}
      />
    );
    const childrenScaleLoader = !isComponent && isLoading && loader;
    const componentScaleLoader = isComponent && isLoading && loader;

    return (
      <div
        className={classnames(classes.container, {
          [classes.disableEvents]: isLoading,
        })}
      >
        <Component {...(sendIsLoading && { isLoading })} {...props}>
          {renderedChildren}
          {childrenScaleLoader}
        </Component>
        {withOverlay && isLoading && <div className={classes.overlay} />}
        {componentScaleLoader}
      </div>
    );
  }

  LoadableComponent.defaultProps = {
    transparent: false,
    children: null,
    isComponent: false,
    sendIsLoading: false,
    withOverlay: false,
    LoadingIndicatorStyle: {},
  };

  LoadableComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    isLoading: PropTypes.bool.isRequired,
    classes: PropTypes.shape().isRequired,
    transparent: PropTypes.bool,
    isComponent: PropTypes.bool,
    sendIsLoading: PropTypes.bool,
    withOverlay: PropTypes.bool,
    LoadingIndicatorStyle: PropTypes.shape(),
  };

  return withStyles(styles)(LoadableComponent);
};

export default loadable;
