// reducers
import layout from './reducers/layout';

// actions
import hideSidebar from './actions/hideSidebar';
import minimizeSidebar from './actions/minimizeSidebar';
import toggleSidebarHide from './actions/toggleSidebarHide';
import toggleSidebarMinimize from './actions/toggleSidebarMinimize';

export const LayoutActions = {
  minimizeSidebar,
  hideSidebar,
  toggleSidebarMinimize,
  toggleSidebarHide,
};

export default layout;
