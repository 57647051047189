import getUser, { setUser } from './getUser';
import updateUser from './updateUser';
import updateMe from './updateMe';

import deactivateUser from './deactivateUser';
import activateUser from './activateUser';

import getTeacherCourseTemplates from './getTeacherCourseTemplates';
import getHodCourseTemplates from './getHodCourseTemplates';
import getPrincipalCourseTemplates from './getPrincipalCourseTemplates';

import getHodQuestions from './getHodQuestions';
import getPrincipalQuestions from './getPrincipalQuestions';
import getTeacherQuestions from './getTeacherQuestions';

import getPrincipalClasses from './getPrincipalClasses';

const actions = {
  deactivateUser,
  activateUser,
  getUser,
  updateUser,
  updateMe,
  setUser,
  getTeacherCourseTemplates,
  getHodCourseTemplates,
  getPrincipalCourseTemplates,
  getHodQuestions,
  getPrincipalQuestions,
  getTeacherQuestions,
  getPrincipalClasses,
};

export default actions;
