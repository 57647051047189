import { messages as messagesService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const fetchUsersStart = () => ({
  type: Types.FETCH_USERS_START,
});

export const fetchUsersEnd = () => ({
  type: Types.FETCH_USERS_END,
});

export const setUsers = users => ({
  type: Types.SET_USERS,
  payload: users,
});

export default params => async dispatch => {
  try {
    dispatch(fetchUsersStart());
    const { data } = await messagesService.getUsers({ params });
    dispatch(setUsers(data?.users));
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(fetchUsersEnd());
  }
};
