import getCourse from './getCourse';
import setCourse from './setCourse';
import getCourseMaterials from './getCourseMaterials';
import resetCourseMaterials from './resetCourseMaterials';
import createFolder from './createFolder';
import createLink from './createLink';
import deleteMaterial from './deleteMaterial';
import unshareMaterial from './unshareMaterial';
import createFile from './createFile';
import updateFolder from './updateFolder';
import updateNote from './updateNote';
import copyMaterial from './copyMaterial';
import shareMaterial from './shareMaterial';
import updateLink from './updateLink';
import createNoteComment from './createNoteComment';
import deleteNoteComment from './deleteNoteComment';
import editMaterial from './editMaterial';
import getNote from './getNote';
import moveMaterial from './moveMaterial';
import getStudentSemesters from './getStudentSemesters';
import getTeacherSemesters from './getTeacherSemesters';
import createAssessment from './createAssessment';
import getCourseAssessments from './getCourseAssessments';
import getCourseClasswork from './getCourseClasswork';
import submitAssessment from './submitAssessment';
import unSubmitAssessment from './unSubmitAssessment';
import deleteAssessment from './deleteAssessment';
import cancelAssessment from './cancelAssessment';
import getAssessment from './getAssessment';
import updateAssessment from './updateAssessment';
import copyAssessment from './copyAssessment';
import restoreAssessment from './restoreAssessment';
import setAutoSaveState from './setAutoSaveState';
import getAssessmentSubmissions from './getAssessmentSubmissions';
import submitSubmission from './submitSubmission';
import getFolderContent from './getFolderContent';
import resetSelectedCourse from './resetSelectedCourse';
import resetCourseAssessments from './resetCourseAssessments';
import getHiddenGrades from './getHiddenGrades';

const actions = {
  setCourse,
  getCourseMaterials,
  getCourse,
  createFolder,
  createLink,
  deleteMaterial,
  unshareMaterial,
  createFile,
  updateFolder,
  updateNote,
  copyMaterial,
  updateLink,
  createNoteComment,
  deleteNoteComment,
  getNote,
  editMaterial,
  shareMaterial,
  moveMaterial,
  resetCourseMaterials,
  getStudentSemesters,
  getTeacherSemesters,
  createAssessment,
  getCourseAssessments,
  getCourseClasswork,
  getHiddenGrades,
  submitAssessment,
  unSubmitAssessment,
  deleteAssessment,
  cancelAssessment,
  getAssessment,
  updateAssessment,
  copyAssessment,
  restoreAssessment,
  setAutoSaveState,
  getAssessmentSubmissions,
  submitSubmission,
  getFolderContent,
  resetSelectedCourse,
  resetCourseAssessments,
};

export default actions;
