import {
  assessments as AssessmentsServices,
  homeworks as HomeworksServices,
} from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const copyAssessmentStart = () => ({
  type: coursesTypes.COPY_ASSESSMENT_START,
});

export const copyAssessmentEnd = () => ({
  type: coursesTypes.COPY_ASSESSMENT_END,
});

const copyAssessment = (isHomework, assessmentId, data) =>
  isHomework
    ? HomeworksServices.copyHomework({
        homeworkId: assessmentId,
        data,
      })
    : AssessmentsServices.copyAssessment({ assessmentId, data });

export default ({ assessmentId, data, callback, isHomework }) =>
  async dispatch => {
    try {
      dispatch(copyAssessmentStart());
      const result = await copyAssessment(isHomework, assessmentId, data);
      if (callback) callback(result.data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(copyAssessmentEnd());
    }
  };
