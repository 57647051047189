import loginBackground from '../../../common/assets/img/login-background.jpg';
import { warningColor } from '../../../common/assets/jss/appStyle';

export default theme => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${loginBackground})`,
    backgroundPosition: 'center',
    [theme.breakpoints.up(400)]: {
      backgroundSize: 'cover',
    },
  },
  mainWithLogo: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  schoolImage: {
    width: '250px',
    margin: '10px 0px',
    borderRadius: '8px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#f50057',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  warning: {
    background: warningColor,
    borderRadius: 8,
    padding: 5,
  },
  hyperLink: {
    color: 'royalblue',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    fontSize: '14px',
    textAlign: 'center',
    margin: 'inherit',
  },
});
