import { messages as messagesService } from '@coligo-org/fe-common/services';

import { ErrorsActions } from '../../../error/state';
import fetchMessages from './fetchMessages';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const updateMessageStart = () => ({
  type: Types.UPDATE_MESSAGE_START,
});

export const updateMessageEnd = () => ({
  type: Types.UPDATE_MESSAGE_END,
});

export default ({ userId, messageId, callback, messageLabelsConstants }) =>
  async dispatch => {
    try {
      dispatch(updateMessageStart());
      await messagesService.restoreMessage({ messageId });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateMessageEnd());
      dispatch(
        fetchMessages({
          userId,
          params: { label: messageLabelsConstants.MSG_LABEL_TRASH },
        }),
      );
    }
  };
