import { semesters as semesterServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import { SchoolActions } from '../../../school/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const updateItemStart = () => ({
  type: Types.UPDATE_ITEM_START,
});

export const updateItemEnd = () => ({
  type: Types.UPDATE_ITEM_END,
});

export default (schoolId, semesterId, updates, callback) => async dispatch => {
  try {
    dispatch(updateItemStart());
    await semesterServices.updateSemester(semesterId, updates);
    callback();
  } catch (error) {
    logger.log(error);
    if (error.response) {
      dispatch(ErrorsActions.addError(error.response.data.message));
    }
  } finally {
    dispatch(updateItemEnd());
    dispatch(SchoolActions.fetchItem(schoolId));
  }
};
