import auth from '../../auth/state/reducers/auth';
import errors from '../../app/modules/common/error/state';
import layout from '../../app/modules/common/layout/state';
import header from '../../app/modules/common/header/state';
import messages from '../../app/modules/common/messages/state';
import notifications from '../../app/modules/common/notifications/state';
import schoolAdmin from '../../app/modules/school-admin/state';

//  All The Above should be removed When finish the service and state folder
import {
  users,
  announcements,
  linkedAccounts,
  superAdmin,
  orgAdmin,
  parent,
  student,
  questions,
  courses,
  i18n,
  app,
} from '../reducers';

export default {
  app,
  auth,
  linkedAccounts,
  superAdmin,
  orgAdmin,
  schoolAdmin,
  parent,
  errors,
  layout,
  header,
  messages,
  notifications,
  announcements,
  courses,
  users,
  student,
  questions,
  i18n,
};
