import Types from '../types';
import { getDefaultLanguage } from '../../../../common/helpers/currentLanguage';

const INITIAL_STATE = {
  locale: getDefaultLanguage(),
};

export default (reducerState = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SET_LOCALE:
      return { ...reducerState, locale: action.payload };
    default:
      return reducerState;
  }
};
