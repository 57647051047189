import { users as usersServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import { usersTypes } from '../../reducers/types';
import logger from '../../../common/services/logService';

export const updateUserStart = () => ({
  type: usersTypes.UPDATE_USER_START,
});

export const updateUserEnd = () => ({
  type: usersTypes.UPDATE_USER_END,
});

export default ({ userId, user, callback }) =>
  async dispatch => {
    try {
      dispatch(updateUserStart());
      await usersServices.updateUser({ userId, user });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateUserEnd());
    }
  };
