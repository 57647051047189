import { semesters as semestersServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../../../common/error/state';

import logger from '../../../../../../../../../common/services/logService';
import Types from '../types';

export const copySemesterSettingsStart = () => ({
  type: Types.COPY_SEMESTER_SETTINGS_START,
});

export const copySemesterSettingsEnd = () => ({
  type: Types.COPY_SEMESTER_SETTINGS_END,
});

export default ({ semesterId, requestParams, callback }) =>
  async dispatch => {
    try {
      dispatch(copySemesterSettingsStart());
      await semestersServices.copySemesterSettings({
        semesterId,
        params: requestParams,
      });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(copySemesterSettingsEnd());
    }
  };
