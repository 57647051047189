import { classes as ClassesService } from '@coligo-org/fe-common/services';

import Types from '../types';
import logger from '../../../../../../../common/services/logService';
import { ErrorsActions } from '../../../../../common/error/state';

export const fetchCertificatesStart = () => ({
  type: Types.FETCH_CERTIFICATES_START,
});

export const fetchCertificatesEnd = () => ({
  type: Types.FETCH_CERTIFICATES_END,
});

export default ({ semesterId, classId, interval, callback }) =>
  async dispatch => {
    try {
      dispatch(fetchCertificatesStart());
      const { data } = await ClassesService.getClassCertificates({
        semesterId,
        classId,
        interval,
      });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(fetchCertificatesEnd());
    }
  };
