// reducers
import errors from './reducers/errors';

// actions
import addError from './actions/addError';
import resetErrors from './actions/resetErrors';

export const ErrorsActions = {
  resetErrors,
  addError,
};

export default errors;
