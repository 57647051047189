import { combineReducers } from 'redux';
import saveList from './actions/saveList';
import state from './reducers/state';

export const moduleName = 'stages';

export const StagesActions = {
  saveList,
};

export default combineReducers({
  state,
});
