import { schools as schoolsServices } from '@coligo-org/fe-common/services';
import { SchoolActions } from '../../../school/state';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

const saveItemStart = () => ({
  type: Types.SAVE_LIST_START,
});

const saveItemEnd = () => ({
  type: Types.SAVE_LIST_END,
});

export default (schoolId, list) => async dispatch => {
  dispatch(saveItemStart());
  try {
    const activateList = list.filter(item => item.active).map(item => item._id);
    const deactivateList = list
      .filter(item => !item.active)
      .map(item => item._id);
    if (activateList.length)
      await schoolsServices.activateStages(schoolId, activateList);
    if (deactivateList.length) {
      await schoolsServices.deactivateStages(schoolId, deactivateList);
    }
  } catch (error) {
    logger.log(error);
    dispatch(ErrorsActions.addError(error.response.data.message));
  } finally {
    dispatch(SchoolActions.fetchItem(schoolId));
    dispatch(saveItemEnd());
  }
};
