import { combineReducers } from 'redux';

import list from './reducers/list';
import item from './reducers/item';
import state from './reducers/state';
import materialsList from './reducers/materialsList';
import gradableItemsList from './reducers/gradableItemsList';
import gradebookPolicy from './reducers/gradebookPolicy';
import assessmentsList from './reducers/assessmentsList';
import homeworkList from './reducers/homeworkList';
import classworkList from './reducers/classworkList';
import visibleGrades from './reducers/visibleGrades';

export default combineReducers({
  item,
  state,
  list,
  materials: materialsList,
  assessments: assessmentsList,
  homeworks: homeworkList,
  classwork: classworkList,
  gradableItems: gradableItemsList,
  gradebookPolicy,
  visibleGrades,
});
