const moduleName = 'school';

const FETCH_ITEM_START = `${moduleName}/FETCH_ITEM_START`;
const FETCH_ITEM_END = `${moduleName}/FETCH_ITEM_END`;
const SET_ITEM = `${moduleName}/SET_ITEM`;

const UPDATE_ITEM_START = `${moduleName}/UPDATE_ITEM_START`;
const UPDATE_ITEM_END = `${moduleName}/UPDATE_ITEM_END`;

export default {
  FETCH_ITEM_START,
  FETCH_ITEM_END,
  SET_ITEM,

  UPDATE_ITEM_START,
  UPDATE_ITEM_END,
};
