const MINIMIZE_SIDEBAR = 'MINIMIZE_SIDEBAR';
const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
const TOGGLE_SIDEBAR_MINIMIZE = 'TOGGLE_SIDEBAR_MINIMIZE';
const TOGGLE_SIDEBAR_HIDE = 'TOGGLE_SIDEBAR_HIDE';

export default {
  MINIMIZE_SIDEBAR,
  HIDE_SIDEBAR,
  TOGGLE_SIDEBAR_MINIMIZE,
  TOGGLE_SIDEBAR_HIDE,
};
