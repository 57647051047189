import { combineReducers } from 'redux';

import listNotifications from './actions/listNotifications';
import markAllNotificationsAsRead from './actions/markAllNotificationsAsRead';
import markAllNotificationsAsSeen from './actions/markAllNotificationsAsSeen';
import updateNotification from './actions/updateNotification';
import getNotificationsUnseenCount from './actions/getNotificationsUnseenCount';
import getUnreadCount from './actions/getUnreadCount';

import state from './reducers/state';
import unreadCountCalendar from './reducers/unread-count-calendar';
import unreadCountSchedule from './reducers/unread-count-schedule';

export const NotificationsActions = {
  listNotifications,
  markAllNotificationsAsRead,
  markAllNotificationsAsSeen,
  getNotificationsUnseenCount,
  updateNotification,
  getUnreadCount,
};

export default combineReducers({
  state,
  unreadCountCalendar,
  unreadCountSchedule,
});
