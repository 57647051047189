import Types from '../types';

const initialState = {
  isListingAnnouncements: false,
  isGettingAnnouncement: false,
  isCreatingAnnouncement: false,
  isUpdatingAnnouncement: false,
  isDeletingAnnouncement: false,
  isGettingCourseAnnouncements: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.LIST_ANNOUNCEMENTS_START:
      return {
        ...reducerState,
        isListingAnnouncements: true,
      };
    case Types.GET_ANNOUNCEMENT_START:
      return {
        ...reducerState,
        isGettingAnnouncement: true,
      };
    case Types.CREATE_ANNOUNCEMENT_START:
      return {
        ...reducerState,
        isCreatingAnnouncement: true,
      };

    case Types.UPDATE_ANNOUNCEMENT_START:
      return {
        ...reducerState,
        isUpdatingAnnouncement: true,
      };

    case Types.DELETE_ANNOUNCEMENT_START:
      return {
        ...reducerState,
        isDeletingAnnouncement: true,
      };

    case Types.LIST_ANNOUNCEMENTS_END:
      return {
        ...reducerState,
        isListingAnnouncements: false,
      };

    case Types.GET_ANNOUNCEMENT_END:
      return {
        ...reducerState,
        isGettingAnnouncement: false,
      };

    case Types.CREATE_ANNOUNCEMENT_END:
      return {
        ...reducerState,
        isCreatingAnnouncement: false,
      };

    case Types.UPDATE_ANNOUNCEMENT_END:
      return {
        ...reducerState,
        isUpdatingAnnouncement: false,
      };

    case Types.DELETE_ANNOUNCEMENT_END:
      return {
        ...reducerState,
        isDeletingAnnouncement: false,
      };

    case Types.GET_COURSE_ANNOUNCEMENTS_START:
      return {
        ...reducerState,
        isGettingCourseAnnouncements: true,
      };

    case Types.GET_COURSE_ANNOUNCEMENTS_END:
      return {
        ...reducerState,
        isGettingCourseAnnouncements: false,
      };

    default:
      return reducerState;
  }
};
