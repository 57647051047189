import {
  schools as schoolsServices,
  courses as coursesServices,
} from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../../../common/error/state';
import logger from '../../../../../../../common/services/logService';
import Types from '../types';

export const updateItemStart = () => ({
  type: Types.UPDATE_ITEM_START,
});

export const updateItemEnd = () => ({
  type: Types.UPDATE_ITEM_END,
});

export default ({
    courseId,
    data: { thumbnailFile, ...otherProps },
    schoolId,
    callback,
  }) =>
  async dispatch => {
    try {
      dispatch(updateItemStart());
      const payload = { ...otherProps };
      if (thumbnailFile) {
        const formData = new FormData();
        formData.append('image', thumbnailFile);
        const { data } = await schoolsServices.uploadThumbnail({
          schoolId,
          data: formData,
        });
        payload.thumbnail = data.url;
      }

      await coursesServices.updateCourse({ courseId, data: payload });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateItemEnd());
    }
  };
