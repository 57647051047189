import Types from '../types';

const initialState = {
  isLoadingMessages: false,
  isLoadingCourses: false,
  isLoadingUsers: false,
  isCreatingMessage: false,
  isUpdatingMessage: false,
  isDeletingMessage: false,
};

export default (reducerState = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_MESSAGES_START:
      return {
        ...reducerState,
        isLoadingMessages: true,
      };
    case Types.FETCH_USERS_START:
      return {
        ...reducerState,
        isLoadingUsers: true,
      };
    case Types.FETCH_COURSES_START:
      return {
        ...reducerState,
        isLoadingCourses: true,
      };

    case Types.UPDATE_MESSAGE_START:
      return {
        ...reducerState,
        isUpdatingMessage: true,
      };

    case Types.DELETE_MESSAGE_START:
      return {
        ...reducerState,
        isDeletingMessage: true,
      };

    case Types.FETCH_MESSAGES_END:
      return {
        ...reducerState,
        isLoadingMessages: false,
      };

    case Types.FETCH_USERS_END:
      return {
        ...reducerState,
        isLoadingUsers: false,
      };

    case Types.FETCH_COURSES_END:
      return {
        ...reducerState,
        isLoadingCourses: false,
      };

    case Types.UPDATE_MESSAGE_END:
      return {
        ...reducerState,
        isUpdatingMessage: false,
      };

    case Types.DELETE_MESSAGE_END:
      return {
        ...reducerState,
        isDeletingMessage: false,
      };

    default:
      return reducerState;
  }
};
