import { users as usersService } from '@coligo-org/fe-common/services';
import { constants } from '@coligo-org/fe-common';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const setUnreadCountCalendar = count => ({
  type: Types.SET_UNREAD_COUNT_CALENDAR,
  payload: count,
});
export const setUnreadCountSchedule = count => ({
  type: Types.SET_UNREAD_COUNT_SCHEDULE,
  payload: count,
});

export default ({ module }) =>
  async dispatch => {
    try {
      const { data } = await usersService.getUnreadNotificationsCount({
        module,
      });
      switch (module) {
        case constants.SYSTEM_MODULES.CALENDARS_MODULE_NAME:
          dispatch(setUnreadCountCalendar(data.count));
          break;
        case constants.SYSTEM_MODULES.CLASS_SCHEDULES_MODULE_NAME:
          dispatch(setUnreadCountSchedule(data.count));
          break;
        default:
          break;
      }
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    }
  };
