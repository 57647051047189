import Types from '../types';

export default (reducerState = {}, action) => {
  switch (action.type) {
    case Types.SET_ITEM:
      return { ...reducerState, openedItem: action.payload };
    case Types.CLEAR_ITEM: {
      const { openedItem, ...newReducerState } = reducerState;
      return newReducerState;
    }
    case Types.SELECT_ITEM:
      return { ...reducerState, selectedItem: action.payload };
    default:
      return reducerState;
  }
};
