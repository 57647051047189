import { courses as CoursesServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';
import getCourseMaterials from './getCourseMaterials';

export const createFileStart = () => ({
  type: coursesTypes.CREATE_MATERIAL_START,
});

export const createFileEnd = () => ({
  type: coursesTypes.CREATE_MATERIAL_END,
});

export default ({ courseId, data, callback }) =>
  async dispatch => {
    try {
      dispatch(createFileStart());
      await CoursesServices.createFile({ courseId, data });
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(createFileEnd());
      dispatch(getCourseMaterials(courseId));
    }
  };
