import { combineReducers } from 'redux';

import list from './reducers/list';
import state from './reducers/state';

import createItem from './actions/createItem';
import importCourses from './actions/importCourses';
import deleteItem from './actions/deleteItem';
import fetchList from './actions/fetchList';
import updateItem from './actions/updateItem';
import addStudentToCourse from './actions/addStudentToCourse';
import removeStudentFromCourse from './actions/removeStudentFromCourse';
import addTeacherToCourse from './actions/addTeacherToCourse';
import removeTeacherFromCourse from './actions/removeTeacherFromCourse';
import exportCourses from './actions/exportCourses';

export const CoursesActions = {
  fetchList,
  createItem,
  deleteItem,
  updateItem,
  addStudentToCourse,
  removeStudentFromCourse,
  addTeacherToCourse,
  removeTeacherFromCourse,
  importCourses,
  exportCourses,
};

export default combineReducers({
  list,
  state,
});
