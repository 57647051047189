import { classwork as classworkServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getClassworkStart = () => ({
  type: coursesTypes.GET_CLASSWORK_START,
});
export const getClassworkEnd = () => ({
  type: coursesTypes.GET_CLASSWORK_END,
});
export const setCourseClasswork = courseClasswork => ({
  type: coursesTypes.SET_COURSE_CLASSWORK,
  payload: courseClasswork,
});

export default ({ courseId, callback }) =>
  async dispatch => {
    try {
      dispatch(getClassworkStart());
      const { data } = await classworkServices.getCourseClasswork({ courseId });
      dispatch(setCourseClasswork(data));
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getClassworkEnd());
    }
  };
