import { users as usersService } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../../../error/state';
import logger from '../../../../../../common/services/logService';
import Types from '../types';

export const updateNotificationsStart = () => ({
  type: Types.UPDATE_NOTIFICATIONS_START,
});

export const updateNotificationsEnd = () => ({
  type: Types.UPDATE_NOTIFICATIONS_END,
});

export default ({ callback }) =>
  async dispatch => {
    try {
      dispatch(updateNotificationsStart());
      await usersService.markAllNotificationsAsSeen();
      if (callback) callback();
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(updateNotificationsEnd());
    }
  };
