const moduleName = 'academicYears';

const UPDATE_ITEM_START = `${moduleName}/UPDATE_ITEM_START`;
const UPDATE_ITEM_END = `${moduleName}/UPDATE_ITEM_END`;
const COPY_SEMESTER_COURSES_START = `${moduleName}/COPT_SEMESTER_COURSES_START`;
const COPY_SEMESTER_COURSES_END = `${moduleName}/COPT_SEMESTER_COURSES_END`;

export default {
  UPDATE_ITEM_START,
  UPDATE_ITEM_END,
  COPY_SEMESTER_COURSES_START,
  COPY_SEMESTER_COURSES_END,
};
