const SET_LINKED_ACCOUNTS = 'SET_LINKED_ACCOUNTS';

const LIST_LINKED_ACCOUNTS_START = 'LIST_LINKED_ACCOUNTS_START';
const LIST_LINKED_ACCOUNTS_END = 'LIST_LINKED_ACCOUNTS_END';

const ADD_LINKED_ACCOUNT_START = 'ADD_LINKED_ACCOUNT_START';
const ADD_LINKED_ACCOUNT_END = 'ADD_LINKED_ACCOUNT_END';

const REMOVE_LINKED_ACCOUNT_START = 'REMOVE_LINKED_ACCOUNT_START';
const REMOVE_LINKED_ACCOUNT_END = 'REMOVE_LINKED_ACCOUNT_END';

export default {
  SET_LINKED_ACCOUNTS,
  LIST_LINKED_ACCOUNTS_START,
  LIST_LINKED_ACCOUNTS_END,
  ADD_LINKED_ACCOUNT_START,
  ADD_LINKED_ACCOUNT_END,
  REMOVE_LINKED_ACCOUNT_START,
  REMOVE_LINKED_ACCOUNT_END,
};
