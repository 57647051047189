import { users as UsersServices } from '@coligo-org/fe-common/services';
import { ErrorsActions } from '../error';
import logger from '../../../common/services/logService';
import { coursesTypes } from '../../reducers/types';

export const getSemestersStart = () => ({
  type: coursesTypes.GET_SEMESTERS_START,
});

export const getSemestersEnd = () => ({
  type: coursesTypes.GET_SEMESTERS_END,
});

export default ({ teacherId, callback }) =>
  async dispatch => {
    try {
      dispatch(getSemestersStart());
      const { data } = await UsersServices.getTeacherSemesters({ teacherId });
      if (callback) callback(data);
    } catch (error) {
      logger.log(error);
      if (error.response) {
        dispatch(ErrorsActions.addError(error.response.data.message));
      }
    } finally {
      dispatch(getSemestersEnd());
    }
  };
